<template>
    <div class="fundraiser-list">
      <h1>Available Fundraisers</h1>
      <div class="fundraiser-container" v-for="fundraiser in fundraisers" :key="fundraiser.id" :class="{'active': fundraiser.isActive, 'inactive': !fundraiser.isActive}">
        <h2>{{ fundraiser.title }}</h2>
        <p>{{ fundraiser.description }}</p>
        <p>Target Amount: {{ fundraiser.targetAmount }}</p>
        <p>Current Amount: {{ fundraiser.currentAmount }}</p>
        <button @click="viewFundraiser(fundraiser.id)">View Details</button>
        <span v-if="fundraiser.isActive" class="status active">Active</span>
        <span v-else class="status inactive">Inactive</span>
      </div>
    </div>
  </template>
  <script>
  export default {
    name: 'FundraiserList',
    data() {
      return {
        fundraisers: []
      };
    },
    created() {
      this.fetchFundraisers();
    },
    methods: {
      async fetchFundraisers() {
        try {
          const response = await this.$store.dispatch('fetchFundraisers');
          this.fundraisers = response.data;
        } catch (error) {
          console.error('Failed to fetch fundraisers:', error);
        }
      },
      viewFundraiser(id) {
        this.$router.push(`/fundraisers/${id}`);
      }
    }
  };
  </script>
  
  <style scoped>
  .fundraiser-list {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.5em;
    color: #333;
  }
  
  .fundraiser-container {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    transition: background-color 0.3s, transform 0.3s;
    position: relative;
  }
  
  .fundraiser-container.active {
    background-color: #e6ffed;
    border-color: #28a745;
  }
  
  .fundraiser-container.inactive {
    background-color: #ffe6e6;
    border-color: #dc3545;
  }
  
  .fundraiser-container:hover {
    transform: scale(1.02);
  }
  
  h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #333;
  }
  
  p {
    margin: 5px 0;
    color: #666;
  }
  
  button {
    padding: 10px 20px;
    font-size: 1em;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  button:hover {
    background-color: #0056b3;
    transform: scale(1.1);
  }
  
  .status {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 5px 10px;
    border-radius: 4px;
    font-weight: bold;
    color: #fff;
  }
  
  .status.active {
    background-color: #28a745;
  }
  
  .status.inactive {
    background-color: #dc3545;
  }
  </style>
  