<template>
  <div class="login-wrapper">
    <div class="login">
      <h1>Login</h1>
      <form @submit="login">
        <input v-model="userEmail" type="email" placeholder="Email" required />
        <input
          v-model="password"
          type="password"
          placeholder="Password"
          required
        />
        <button type="submit">Go to my account</button>
      </form>
      <div class="register-section">
        <p>
          Don't have an account?
          <router-link to="/register">Register here</router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import BASE_URL from "@/config";
import axiosInstance from '@/instance';
import axios from "axios";
import { jwtDecode } from "jwt-decode";

export default {
  name: "LoginPlace",
  data() {
    return {
      userEmail: "",
      password: "",
      accessToken: "",
      refreshToken: "",
      loading: false,
    };
  },
  methods: {
    async login(event) {
      event.preventDefault();
      this.loading = true;
      const userDetails = {
        userEmail: this.userEmail,
        password: this.password,
      };
      axios
        .post(BASE_URL + "/auth/login", userDetails, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(async (res) => {
          if (res.data.success) {
            // Get tokens and commit them to Vuex store
            const accessToken = res.data.access_token;
            const refreshToken = res.data.refresh_token;

            this.$store.commit("setAccessToken", accessToken);
            this.$store.commit("setRefreshToken", refreshToken);
            const token = res.data.access_token;
            const decodedUser = token?.length && jwtDecode(token);
            this.$store.commit("setUser", decodedUser); 
            axiosInstance.defaults.headers.common["Authorization"] =
            await `Bearer ${accessToken}`;
            await this.$router.push("/dash");
          } else {
            console.log("errrr");
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log("Login request failed", error);
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.login-wrapper {
  background-image: url(@/assets/images/plant-coins.png);
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login {
  width: 400px;
  padding: 40px 20px;
  text-align: center;
  background-color: #fff;
  border-radius: 20px;
  opacity: 0.9;
}

.login h1 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #333;
}

.login form {
  display: flex;
  flex-direction: column;
}

.login input {
  padding: 10px;
  margin-bottom: 15px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 20px;
  outline: none;
  transition: border-color 0.3s;
}

.login input:focus {
  border-color: #007bff;
}

.login button {
  padding: 10px;
  font-size: 1em;
  color: #fff;
  background-color: #f0a500;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.login button:hover {
  background-color: #cc8400;
}

.register-section {
  margin-top: 20px;
}

.register-section p {
  margin: 0;
  font-size: 1em;
}

.register-section a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.register-section a:hover {
  text-decoration: underline;
}
</style>
