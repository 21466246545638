import axios from 'axios';
import BASE_URL from '@/config';

export const handleLogout = () => {
    window.location.href = 'https://localhost:8081';
};

const axiosInstance = axios.create({
    BASE_URL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

axiosInstance.interceptors.response.use(response => {
    // console.log(response.headers);	
	
    return response;
}, (error) => {
		const statusCode = error.response ? error.response.status : null;

		if (statusCode === 401) {
			handleLogout();
		}

		if (statusCode >= 500) {
			// show server error
		}

		if (statusCode === 400) {
			handleLogout();
		}

		return Promise.reject(error);
	});


export default axiosInstance;