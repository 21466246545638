<template>
    <div>
<HomePageHeader></HomePageHeader>
    <div class="donations">
        <div class="donations-content" > 
            <img style="height: 100%;" src="@/assets/images/zigzag-texture.webp"> 
                    <div class="title-center">
                        <h3>{{singleFund.title}}</h3>
                    </div> 
            <img style="height: 100%;" src="@/assets/images/zigzag-texture.webp">
        </div>
        <div style="display: flex; justify-content: center; margin-top: 10px; height: 200px; width: 100%;">
            <img src="@/assets/images/mchangawide.webp" alt="Smart People Africa" />
        </div>
        <div style="display: flex;justify-content:space-between;padding: 0 10rem">
            <div style="display: flex; gap: 10px; margin-top: 15px"> 
                <img src="@/assets/images/padlock.webp"/>
                <p>Treasurer Controlled </p><div class="donations-i">i</div>
            </div>
            <div style="display: flex; gap: 10px;margin-top: 15px">
                <img src="@/assets/images/verified.webp"/>
                <p>Verified Fundraiser</p><div class="donations-i">i</div>
            </div>
            <div style="display: flex; gap: 10px;margin-top: 15px">
                <img src="@/assets/images/padlock.webp"/>
                <p>Treasurer Controlled </p><div class="donations-i">i</div>
            </div>
        </div>
        <div style="display: flex;justify-content: center; gap: 10px; margin-top: 30px;">
              <button class="donate-btn" @click="donate">DONATE</button>  
              <button class="share-btn" @click="share">SHARE</button> 
        </div>
        <!-- <div style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
            <h1>Description</h1>
            <div>
                <span>The fundraisers Description</span>
            </div>
        </div> -->
        <div style="padding:30px 50px; display: flex; flex-direction: column;" >
            <div style="background-color: #475b06; padding: 15px;text-align: center; color: #fff;margin-bottom: 0;font-size: 20px;">
                <p>Select Donation Method</p>
            </div>
            <div style="background-color: #eee;">
                <div style="justify-content: center; align-items: center; display: flex; ">
                    <img style="height: 80px; width: 150px;" src="@/assets/images/mpesa.webp">
                </div>
                <div style="margin-bottom: 10px; margin-top: 10px; display: flex; align-items: center; justify-content: center; gap: 10px;">
                    <button style="height: 50px; width: 150px; border-radius: 30px;  background-color: #fff; border: 1px solid #c5c5c5" @click="setDonationAmount(200)">200</button>
                    <button style="height: 50px; width: 150px; border-radius: 30px;  background-color: #fff; border: 1px solid #c5c5c5;" @click="setDonationAmount(1000)">1000</button>
                    <button style="height: 50px; width: 150px; border-radius: 30px;  background-color: #fff; border: 1px solid #c5c5c5;" @click="setDonationAmount(5000)">5000</button>
                </div>
                <div style="display: flex; justify-content: center; padding: 20px; ">
                    <button style="padding: 10px;font-size: 1em;color: #fff;margin: 8px;background-color: #f0a500;border: none;border-radius: 20px;cursor: pointer;transition: background-color 0.3s;" type="button" @click="decreaseTarget">-</button>
                    <input style="width: 40%; height: 30px; border: 1px solid #c5c5c5; border-radius: 20px; padding: 20px; margin-top: 10px;" v-model="donationAmount" type="number" placeholder="Enter Amount in Ksh" required>
                    <button style="padding: 10px;font-size: 1em;color: #fff;margin: 8px;background-color: #f0a500;border: none;border-radius: 20px;cursor: pointer;transition: background-color 0.3s;" type="button" @click="increaseTarget">+</button>
                    <div style="display: flex; justify-content: flex-end;">
                        <input style="width: 500px; height: 30px; border: 1px solid #c5c5c5; border-radius: 20px; padding: 20px; margin-top: 10px;"  v-model="mobileNumber" type="text" placeholder="07" required>
                    </div>
                </div>

                <div style="display: flex; justify-content: center; align-items: center; margin-top: 10px">
                    <button class="donate-btn" @click="donateNow">DONATE NOW</button>  
                </div>
            </div>
        </div>
    </div>
    <HomePageFooter></HomePageFooter>
</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import axios from 'axios';
import HomePageHeader from './UI/HomePageHeader.vue';
import HomePageFooter from './UI/HomePageFooter.vue';
import BASE_URL from '@/config';

export default{
    name: 'DonationView',
    data() {
        return{
            title: '',
            donationAmount: 0,
            mobileNumber: '',
            loading: false
        }
    },
    components: {
        HomePageHeader,
        HomePageFooter
    },
    computed: {
    ...mapState({
        fundraisers: (state) => state.fundraisers,
        singleFund: (state) => state.singleFund
    }),
    ...mapGetters(['fundraisers', 'fundraiser', 'user', 'singleFund']),
    },
    methods: {
        async donateNow(){
            this.loading = true;
            const donationDetails = {
                fundraiserId: this.singleFund.fundraiserId,
                donationAmount: this.donationAmount,
                mobileNumber: this.mobileNumber
            }
            axios.post(BASE_URL+`/api/v3/pay/donate`, donationDetails, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(async(res)=>{
                console.log("see number =>",res)
                if(res.data.success) {
                    console.log('Mobile number is here', res.data.data.result);
                    // window.location.href =`/dashboard`;      
                }else{
                    console.log("errrr")
                }
                this.loading = false;
            })
            .catch(error => {
                console.error("Fundraiser Activation failed",error);
                this.loading = false;
            });
        },
        setDonationAmount(donationAmount) {
            this.donationAmount = donationAmount; 
        },
        decreaseTarget() {
            if (this.donationAmount > 0) {
                this.donationAmount -= 100;
            }
        },
        increaseTarget() {
            this.donationAmount += 100;
        },
        share() {
            this.$router.push("/share");
        },
    },
}
</script>

<style>
.donations-content{
  background-color: #005803;
  /* background-image: url(@/assets/images/top-banner-resources.jpg); */
  width: 100%;
  padding-top: 50px;
  height: 300px; 
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* position: relative; */
}

.title-center {
    margin: 60px;
}
.title-center h3 {
    font-family: museo_sans_rounded900;
    font-size: 55px;
    line-height: normal;
    color: #fcc82c;
    font-weight: 400;
}
.title-center h4{
    font-family: Permanent Marker, cursive;
    font-size: 15px;  
    color: #c1f7d5;
}

.donations-i{
    width: 25px; 
    height: 25px; 
    border-radius: 9999px; 
    background-color: #005803; 
    display: flex; 
    align-items: center; 
    justify-content: center;
    color: #fff;
}

.donate-btn{
    background-color: #fcc82c;
    border: 1px solid #fcc82c;
    color: black;
    font-weight: 400;
    height: 50px; 
    width: 150px; 
    border-radius: 30px;
}
.share-btn{
    height: 50px; 
    width: 150px; 
    border-radius: 30px;
    border: 1px solid #fcc82c;
    color: black;
    font-weight: 400;
}
.donate-btn:hover{
    background-color: #cc8400;
    color: #fff;
    border: none;
}

.share-btn:hover{
    background-color: black;
    color: #fff;
    border: none;
}
</style>