<template>
  <div>
    <MyFundraiserDetailsCard></MyFundraiserDetailsCard>
    <div style="display: flex; justify-content: center; align-items:center; flex-direction:column" class="image-upload">
      <h2 style="font-size: 30px;padding-bottom:10px;">Upload Your Images</h2>
      <form @submit.prevent="uploadImage">
        <div style="display: flex; justify-content:center; align-items:center; padding-bottom:10px;gap:10px;margin:auto">
          <label for="image">Upload Image:</label>
          <input type="file" id="imageId" @change="uploadFile" accept=".jpg, .jpeg, .png,"/>
        </div>
        <button style="border: 1px solid #FCC82C; border-radius: 20px; background-color:#FCC82C; padding:5px; display:flex;align-items:center;justify-content:center;margin:auto;" type="submit">Upload Image</button>
      </form>
    </div>
  </div>
  </template>
  
  <script>
  import { mapGetters, mapState } from 'vuex';
  import MyFundraiserDetailsCard from '../UI/MyFundraiserDetailsCard.vue';


  export default {
    name: 'ImageUpload',
    components: {
      MyFundraiserDetailsCard
    },
    data() {
      return {
        selectedFile: null,
        errorMessage: ''
      };
    },
    computed: {
      ...mapState({
        singleFund: state => state.singleFund,
        file: state => state.file
      }),
      ...mapGetters(['file', 'singleFund'])
    },
    methods: {
      async uploadFile(event) {
        this.$store.commit('setProcessing', true)        
        console.log("hellooooooooooo",event.target.files[0]);
        const formData = new FormData();
        formData.append("file", event.target.files[0]);
        console.log("formData file:", formData.get('file'));
        await this.$store.dispatch('uploadFile', formData);
      }
    }
  };
  </script>
  
  <style scoped>
  .image-upload {
    flex-grow: 1;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .image-upload form {
    margin-bottom: 20px;
  }
  
  .image-gallery {
    margin-top: 20px;
  }
  
  .image-item {
    display: inline-block;
    margin: 10px;
    text-align: center;
  }
  
  .image-item img {
    max-width: 150px;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: block;
    margin-bottom: 5px;
  }
  
  .image-item button {
    padding: 5px 10px;
    border: 1px solid #ccc;
    background-color: #fff;
    cursor: pointer;
  }
  
  .image-item button:hover {
    background-color: #eee;
  }
  </style>
  