<template>
  <div id="app">
    <main>
      <HomePageHeader></HomePageHeader> 
      <div class="banner-content" >  
          <div style="display: flex; justify-content: space-between; align-items: center; width: 100%; height: 100%; margin-top: 30px;"> 
        <img style="height: 100%; padding-top: 0px;"   src="@/assets/images/zigzag-texture.webp">
          <div class="banner-center" style="z-index: 10;">
            <h1>Fundraising</h1>
            <h2>Simple. Fast. Secure.</h2>
              <div class="search-bar">
                <button class="cta-btn"  @click="goToRegister">START TODAY</button>
                <div style="display: flex; align-items: center;">
                    <button class="search-btn">
                      <img  src="@/assets/images/ic-search.webp">
                    </button>
                  <input type="text" placeholder="Find a Fundraiser...">
                </div>
              </div>
          </div>
         <img  style="height: 100%;" src="@/assets/images/zigzag-texture.webp">
        </div>
        <img class="curve" style="z-index: 1" src="@/assets/images/banner-curve-overlay.webp">
        <div class="stats" style="width: fit-content;">
                  <div class="container">
                    <div class="stat-item">
                      <img src="@/assets/images/ic-fundraiser-started.webp" alt="Fundraising Icon">
                      <div>
                        <p class="stat-number">{{ fundraisers.length }}</p>
                        <p>Fundraising Campaigns Started</p>
                      </div>
                    </div>
                    <div class="stat-item">
                      <img src="@/assets/images/ic-supporters.webp" alt="Supporters Icon">
                      <div>
                        <p class="stat-number">{{ donations.length }}</p>
                        <p>Supporters</p>
                      </div>
                    </div>
                    <div class="stat-item">
                      <img src="@/assets/images/cng-globe.webp" alt="Countries Icon">
                      <div>
                        <p class="stat-number">{{ countries }}</p>
                        <p>Countries</p>
                      </div>
                    </div>
                  </div>
              </div> 
      </div>    

      <section class="why-mchanga">
        <h2 style="padding-top: 30px;">Why SPA - Fundraiser?</h2>
        <div class="all-features" style="display: flex;flex-shrink: 1; flex-wrap: wrap; gap: 40px">
        <div class="why-mchanga-featuresddd" style="height: 200px; width: 200px;">
          <div style="display: flex; flex-direction: column;align-items: center; justify-content: center; row-gap: 20px;" >
            <span><img src="@/assets/images/1.webp" alt="Easy setup"></span>
            <span style="width: 100%; word-wrap: break-word;">
              Easy set up and management    
            </span>
          </div>
        </div>

        <div class="why-mchanga-featuresddd" style="height: 200px; width: 200px;">
          <div style="display: flex; flex-direction: column;align-items: center; justify-content: center; row-gap: 20px;" >
            <span><img src="@/assets/images/2.webp" alt="Mobile money"></span>
            <span style="width: 100%; word-wrap: break-word;">
              Mobile money donations            
            </span>
          </div>
        </div>

        <div class="why-mchanga-featuresddd" style="height: 200px; width: 200px;">
          <div style="display: flex; flex-direction: column;align-items: center; justify-content: center; row-gap: 20px;" >
            <span><img src="@/assets/images/3.webp" alt="Mobile money"></span>
            <span style="width: 100%; word-wrap: break-word;">
              Share campaign with a single click
            </span>
          </div>
        </div>

        <div class="why-mchanga-featuresddd" style="height: 200px; width: 200px;">
          <div style="display: flex; flex-direction: column;align-items: center; justify-content: center; row-gap: 20px;" >
            <span><img src="@/assets/images/4.webp" alt="Mobile money"></span>
            <span style="width: 100%; word-wrap: break-word;">
              Automated campaign reports
            </span>
          </div>
        </div>

        <div class="why-mchanga-featuresddd" style="height: 200px; width: 200px;">
          <div style="display: flex; flex-direction: column;align-items: center; justify-content: center; row-gap: 20px;" >
            <span><img src="@/assets/images/5.webp" alt="Mobile money"></span>
            <span style="width: 100%; word-wrap: break-word;">
              Enhance donations security            
            </span>
          </div>
        </div>

        <div class="why-mchanga-featuresddd" style="height: 200px; width: 200px;">
          <div style="display: flex; flex-direction: column;align-items: center; justify-content: center; row-gap: 20px;" >
            <span><img src="@/assets/images/6.webp" alt="Mobile money"></span>
            <span style="width: 100%; word-wrap: break-word;">
              Flexible withdrawal options
            </span>
          </div>
        </div>

        <div class="why-mchanga-featuresddd" style="height: 200px; width: 200px;">
          <div style="display: flex; flex-direction: column;align-items: center; justify-content: center; row-gap: 20px;" >
            <span><img src="@/assets/images/7.webp" alt="Mobile money"></span>
            <span style="width: 100%; word-wrap: break-word;">
              Live customer care            
            </span>
          </div>
        </div>

        <div class="why-mchanga-featuresddd" style="height: 200px; width: 200px;">
          <div style="display: flex; flex-direction: column;align-items: center; justify-content: center; row-gap: 20px;" >
            <span><img src="@/assets/images/8.webp" alt="Mobile money"></span>
            <span style="width: 100%; word-wrap: break-word;">
              Global donation options            
            </span>
          </div>
        </div>
      </div>
      </section>

      <section class="fundraiser-section">
        <div class="container">
          <div class="steps">
            <h1>3 Easy Steps</h1>
            <div class="step">
              <img src="@/assets/images/ic-start-fundraiser.webp" alt="Start Icon">
              <h2>Create Your Fundraiser</h2>
              <p class="paragraph_1">It'll take only 2 minutes. Just tell us a few details about you and what you are raising funds for.</p>
            </div>
            <div class="step">
              <img src="@/assets/images/ic-share-fundraiser.webp" alt="Share Icon">
              <h2>Share your fundraiser with your supporters</h2>
              <p>All you need to do is consistently share the fundraiser with your friends, family, and online community. In no time, support will start pouring in.</p>
            </div>
            <div class="step">
              <img src="@/assets/images/ic-withdraw.webp" alt="Manage Icon">
              <h2>Manage your fundraiser</h2>
              <p>View your progress, add custodians, accept mobile money and card donations and more.</p>
            </div>
            <button class="start-btn" @click="goToRegister">START A FUNDRAISER</button>
            <button class="learn-btn">LEARN MORE</button>
          </div>
          <div class="phone-image">
            <img src="@/assets/images/device-contour.webp" alt="Mobile Phone">
              <div class="background-image">
                <img src="@/assets/images/thm-shape-1.webp" alt="Background Image">
              </div>
          </div>
        </div>
      </section>
    <CarouselTrial></CarouselTrial>
    <HomePageFooter></HomePageFooter>
    </main>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState, mapActions, mapGetters } from 'vuex';
import BASE_URL from '@/config';
import HomePageHeader from './UI/HomePageHeader.vue';
import HomePageFooter from './UI/HomePageFooter.vue';
import CarouselTrial from './UI/CarouselTrial.vue';
// import { VueperSlides, VueperSlide } from 'vueperslides'
// import 'vueperslides/dist/vueperslides.css'


export default {
  name: "HomePage",
  components: {
    HomePageFooter,
    HomePageHeader,
    CarouselTrial
    },
  computed: {
    ...mapState(['fundraisers', 'donations', 'searchResults']),
    ...mapGetters(['fundraisers', 'donations','fundraiser']),
  },
  created() {
      this.$store.dispatch('fetchFundraisers');
      console.log("point 1 ")
    //  this.$store.dispatch('fetchDonations')
      // console.log("point 2 ")
      // this.$store.dispatch('fetchStats');    
    },
  methods: {
    ...mapActions(['searchFundraiser', 'fetchStats']),
    goToRegister() {
      this.$router.push("/register");
    },
    prev() {
      if (this.currentSlide > 0) {
        this.currentSlide--;
      }
    },
    next() {
      if (this.currentSlide < this.fundraisers.length - this.perPage) {
        this.currentSlide++;
      }
    },
    async searchFundraiser() {
      try {
        const response = await axios.get(BASE_URL+`api/fundraiser/search`, {
          params: { query: this.searchQuery },
        });
        this.$store.commit('setSearchResults', response.data);
      } catch (error) {
        console.error('Error fetching fundraisers:', error);
      }
    },
  }
};
</script>

<style scoped>
/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */
.custom-slide-content {
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
  text-align: center;
}

.custom-slide-content h3 {
  font-size: 1.5em;
  color: #333;
}

.custom-slide-content p {
  font-size: 1em;
  color: #666;
}

.vueper-slides {
  width: 80%;
  margin: 0 auto;
}



body {
  font-family: Arial, sans-serif;
  background-color: #f7f7f7;
}
/* Main section styling */
 
.banner-content{ 
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #005803;
  width: 100%;
  height: 400px;  
  padding: 0px 0px 30px 0px; 
  z-index: 0;
}
.curve {
  margin-top: -160px;
}

.banner-center{
  flex-direction: column;
  display: flex;
  align-items: center;
}

.banner-center h1 {
  font-size: 55px;
  font-family: museo_sans_rounded900;
  font-weight: 400;
  margin-bottom: 10px;
  color: #FCC82C;
  line-height: normal;
}

.banner-center h2{
  font-size: 55px;
  font-family: museo_sans_rounded900;
  color: #fff;
}

.cta-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}
 .cta-btn {
  background-color: #FCC82C;
  height: 60px;
  padding: 0 40px;
  font-family: Nunito, sans-serif;
  color: #000000;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 20px;
} 

.cta-btn:hover {
  background-color: #cc8400;
  color: #ccc;
}

.search-bar {
  display: flex;
  justify-content: space-between;
  border: none;
  font: bold;
  gap: 10px;
}

.search-bar input {
  height: 50px;
  border: none;
  border-radius: 0 20px 20px 0;
  width: 300px;
  font-size: 1rem;
}

.search-btn {
  height: 50px;
  width: 12%;
  background-color: #FCC82C;
  border: none;
  border-radius: 50% 0 0 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  font-weight: 400;
  cursor: pointer;
}

.search-btn:hover {
  background-color: #cc8400;
}

.search-results {
  margin-top: 1em;
}
.search-result {
  padding: 0.5em;
  border: 1px solid #ccc;
  margin-bottom: 0.5em;
}
/* Stats section styling */
.stats {
  background-color: #e1e1e1;
  text-align: center;
  z-index: 1;
  width: 100%;
  margin-top: -170px;
}


.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  margin: 10px;
}

.stat-item img {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.stat-number {
  font-size: 32px;
  font-weight: bold;
  color: #333;
}

.stat-item p {
  margin: 5px 0;
  color: #666;
}

/* .hero-banner-overlay{
  position: relative;
  margin-top: 200px;
  z-index: 0;
} */
/* Why SPA - Fundraiser */
.why-mchanga {
  padding: 100px 200px;
  background-color: #f9f9f9;
  justify-content: center;
  text-align: center;
  align-content: center;
}

.why-mchanga h2 {
  font-size: 2em;
  text-align: center;
  padding-left: 20px;
  align-items: center;
  align-content: center;
  margin: 0 0 30px 0;
  font-weight: 700;
  text-transform: none;
  letter-spacing: normal;
  color: #475b06;
  line-height: normal;
}
/* .features {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
}

.feature {
  flex: 1 1 calc(25% - 40px);
  margin: 20px;
  text-align: center;
}

.feature img {
  width: 50px;
  height: 50px;
}

.feature p {
  margin-top: 10px;
  font-size: 1em;
  color: #333;
  text-align: center;
} */

/*Fundraiser Section */
.fundraiser-section {
  background-color: #f9f9f9;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 0;
  margin-right: 0;
}

.fundraiser-section .container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
}

.steps {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  max-width: fit-content;
}
.steps h1 {
    font-family: panton_blackregular;
    font-size: 35px;
    text-transform: none;
    letter-spacing: normal;
    color: #475b06;
    line-height: normal;
    justify-content: center;
    align-items: center;
    margin-left: 50%;
    padding-bottom: 10px;
    font-weight: 700;
}
/* .steps.step {
  display: flex;
  align-items: center;
} */
.steps .step h2 {
  font-size: 1.2em;
  color: #005803;
  margin-bottom: 10px;
  margin-left: 50px;
}

.steps .step p {
  color: #333;
  font-size: 17px;
  text-align: left;
  margin-left: 50px;
}
.paragraph_1{
  color: #333;
  font-size: 17px;
  text-align: left;
  margin-left: 50px;
}

.steps.step img {
  width: 50px;
  height: auto;
  margin-right: 20px;
}

.start-btn, .learn-btn {
  display: block;
  padding: 15px 30px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  margin: 10px 0;
  width: 80%;
  margin-left: 40px;
}
.start-btn{
  background-color: #FCC82C;
  color: #070606;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
}
.start-btn:hover {
  background-color: #cc8400;
  color: white;
}
.learn-btn {
  background-color: #f5f5f5;
  border: 2px solid #FCC82C;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.learn-btn:hover {
  background-color: #272622; 
  color: #f5f5f5;
  border: 2px solid #272622;
}
.phone-image {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
}

.phone-image img {
  width: 80%;
  height: 600px;
  z-index: 1;
  margin-bottom: 10px;
}
.background-image {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 22px;
  width: 100%;
  height: 100%;
  z-index: 0; 
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-image img {
  width: 100%;
  height: auto;
}
.homepage-carousel{
    display: flex;
    gap: 10px;
}
.fundraiser-carousel{
  display: flex;
  justify-content: center; 
    display:flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}
.carousel-image {
    height: 100px;
    width:100px;
    object-fit: contain;
}
  .fundraiser-details {
  border: 1px solid #ddd;
    border-radius: 8px;
    display: flex; 
    padding: 0px;
    background-color: #ffffff;
    height: 300px;
    width: 400px;
    text-align: center;
    flex-direction: column;
}
.fundraiser-details h2 {
    margin-top: 50px;
  font-size: 1.5rem;
}

.fundraiser-details p {
  margin: 5px 0;
  font-size: 1rem;
}
.fundraiser-details button{
    background-color: #fff;
    color: #070606;
    border: 1px solid #FCC82C;
    border-radius: 20px;
    margin-top: 70px;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    height: 30px;
}
.fundraiser-details button:hover{
    background-color: #070606;
    color: white;
    border: none;
}

</style>


