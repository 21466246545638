<template>
  <div>
    <MyFundraiserDetailsCard :fundraiser="fundraiser"></MyFundraiserDetailsCard>
    <div class="fundraiser-patrons">
      <h2>Invite a Patron</h2>
      <p class="info-text">
        Are you ready to invite a Patron to your Fundraiser?
        A patron's role is to advocate for your fundraiser and ensure that it meets its target. 
        You're allowed a maximum of 5 patrons. 
        When the patron accepts the invite within 24 hours, their name, personal message, and photo (optional) will be shown on your fundraiser sharepage.
    </p>
      <form @submit.prevent="invitePatron">
        <div class="form-group">
          <label for="patronName">Name:</label>
          <input type="text" id="patronName" v-model="patronName" required />
        </div>
        <div class="form-group">
          <label for="patronEmail">Email:</label>
          <input type="email" id="patronEmail" v-model="patronEmail" required />
        </div>
          <button class="patron-btn" type="submit">Invite Patron</button>
      </form>
      <div v-if="patrons.length" class="patron-list">
        <h3>Invited Patrons</h3>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Message</th>
              <th>Photo</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(patron, patronId) in patrons" :key="patronId">
              <td>{{ patron.patronName }}</td>
              <td>{{ patron.patronEmail }}</td>
              <td>{{ patron.message }}</td>
              <td>
                <img v-if="patron.photo" :src="patron.photo" alt="Patron Photo" class="patron-photo" />
                <span v-else>No Photo</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  </template>
  
  <script>
  import { mapGetters, mapState } from 'vuex';
  import MyFundraiserDetailsCard from '../UI/MyFundraiserDetailsCard.vue';
  
  export default {
    name: 'FundraiserPatron',
    components: {
      MyFundraiserDetailsCard
    },
    data() {
      return {
        patronName: '',
        patronEmail: '',
      };
    },
    computed: {
      ...mapState({
      singleFund: state => state.singleFund,
      patrons: state => state.patrons,
      }),
      ...mapGetters(['singleFund'])
    },
    methods:{
      async invitePatron(){
        this.$store.commit('setProcessing', true)
        console.log("invitiiiiiiiing 1");
        const patronData = {
          patronName: this.patronName,
          patronEmail: this.patronEmail,
        }
        this.$store.dispatch('invitePatron', patronData);
      }
    }
  };
  </script>
  
  <style scoped>
  .fundraiser-patrons {
    flex-grow: 1;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  .info-text {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 1.5;
    color: #555;
    }
  .form-group { 
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .patron-btn{
    border: #fcc82f;
    background-color: #fcc82f;
    border-radius: 20px;
    padding: 5px;
    width: 100px;
    text-align: center;
  }
  
  .patron-btn:hover {
    background-color: #cc8400;
  }
  
  .patron-list {
    margin-top: 20px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    padding: 10px;
    border: 1px solid #ccc;
  }
  
  th {
    background-color: #f2f2f2;
  }
  
  .patron-photo {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 5px;
  }
  </style>
  