<template>
    <div class="flex w-full">
        <div>
        <main>
          <div >
            <h2>My Fundraisers</h2>
            <h3>Pick a fundraiser to manage</h3>
            <div class="fundraiser-container">
              <div class="add-fundraiser-card" @click="addNewFundraiser">
                <div class="plus-icon">+</div>
                <button class="addNewFundraiser" @click="addNewFundraiser">
                  Add New Fundraiser
                </button>
              </div>
              <FundraiserCard></FundraiserCard>
            </div>
          </div>
        </main>
        <router-view></router-view>
      </div>
    </div>
  </template>

<script>
import { mapGetters, mapState } from 'vuex'; 
import FundraiserCard from '../UI/FundraiserCard.vue';
 
export default{
    name: 'DashBoard',
    components:{
      FundraiserCard
    },
    data(){
      return {
        isDashBoard: false,
      }
    },
  
    computed:{
      ...mapState({
        fundraisers: (state) => state.fundraisers,
        fundraiserCard: (state) => state.fundraiserCard
      }),
        ...mapGetters(['user'])
    },
    methods: {
    
      addNewFundraiser() {
        this.$router.push('/addFundraiser');
      },
      checkIfDashboard(route) {
        this.isDashBoard = route.path === '/dash';
      },
    },
    watch: {
    '$route'(to) {
      this.checkIfDashboard(to);
      }
    },
    mounted() {
      this.checkIfDashboard(this.$route);
      },
}
</script>

<style scoped>
.dashboard-container {
  display: flex;
  height: 100vh; /* Full viewport height */
  overflow-x: hidden;
}

.main-content {
  flex-grow: 1; /* Take up remaining space */
  padding: 20px;
  box-sizing: border-box;
  transition: margin-left 0.3s ease;
}
.heading-container{
    display: flex; 
    justify-content:center;
    align-items: center;
    padding-bottom: 5px;
}
.heading{
    font-size: 30px;
    font-weight: 500;

}
.heading i{
    font-weight: 900;
    color: #fcc82c;
}
 .fundraiser-container {
    /* width: 1150px; */
    display: flex;
    gap: 20px;
    margin-top: 20px;
    flex-wrap: wrap;
    transition: margin-left 0.3s ease;
  }

  
  .add-fundraiser-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 200px;
    background-color: #f8f9fa; 
    border-radius: 8px;
    transition: transform 0.3s;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    border: 2px dashed #ddd;
  }
  
  .add-fundraiser-card:hover {
    transform: translateY(-5px);
    background-color: #e1e2e4;
  }
  .plus-icon {
    font-size: 50px;
    font-weight: bold;
    color: black;
    margin-bottom: 10px;
  }

  .addNewFundraiser {
    display: block;
    width: 50%;
    padding: 10px 0;
    margin-top: 20px;
    border-radius: 20px;
    background-color: #fcc82c;
    color: black;
    border: none;
    cursor: pointer;
    text-align: center;
  }
  .addNewFundraiser:hover{
    background-color: #cc8400;
  }

</style>