<template>
  <div>
    <MyFundraiserDetailsCard></MyFundraiserDetailsCard>
    <div class="custodian-invite">
        <h1>Fundraiser Custodians</h1>
      <h2>Invite a New Custodian</h2>
      <form @submit.prevent="inviteCustodian">
        <div class="form-group">
          <label for="phoneNumber">Mobile Phone Number (International Format):</label>
          <input 
            type="tel" 
            id="phoneNumber" 
            v-model="phoneNumber" 
            placeholder="+1234567890" 
            required 
            pattern="^\+[1-9]\d{1,14}$" 
          />
        </div>
        <button type="submit">Send Invite</button>
      </form>
  
    <div v-if="loading">Sending invite...</div>
    <div v-if="successMessage">{{ successMessage }}</div>
    <div v-if="errorMessage">{{ errorMessage }}</div>

      <h2>Invited Treasurers</h2>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Phone</th>
            <th>Status</th>
            <th>Date Added</th>
            <th>Last Approval</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="treasurer in treasurers" :key="treasurer.phone">
            <td>{{ treasurer.treasurerName }}</td>
            <td>{{ treasurer.treasurerPhone }}</td>
            <td>{{ treasurer.treasurerStatus }}</td>
            <td>{{ treasurer.dateAdded }}</td>
            <td>{{ treasurer.lastApproval }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  </template>
  
  <script>
  import { mapState } from 'vuex';
import MyFundraiserDetailsCard from '../UI/MyFundraiserDetailsCard.vue';

  export default {
    name: 'FundraiserCustodians',
    components: {
      MyFundraiserDetailsCard
    },
    data() {
      return {
        phoneNumber: ''
      };
    },
    computed: {
      ...mapState({
        fundraiser: state => state.fundraiser,
        treasurers: state => state.treasurers,
      }),
      ...mapState(['loading', 'successMessage', 'errorMessage']),
    },
  }
  </script>
  
  <style scoped>
  .custodian-invite {
    /* max-width: 600px; */
    margin: left;
    flex-grow: 1;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  label {
    display: block;
    margin-bottom: 10px;
  }
  
  input[type="tel"] {
    display: block;
    width: 100%;
    padding: 8px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  button {
    padding: 10px 20px;
    color: #fff;
    border-radius: 18px;
    background-color: #f0a500;
    border: none;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #cc8400;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  th, td {
    padding: 10px;
    border: 1px solid #ccc;
    text-align: left;
  }
  
  th {
    background-color: #f2f2f2;
  }
  </style>
  