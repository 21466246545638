<template>
  <div>
    <MyFundraiserDetailsCard></MyFundraiserDetailsCard>
    <div class="fundraiser-story">
      <h1>Fundraiser Story</h1>
    </div>
  <div class="story">
      <ckeditor :editor="editor" v-model="editorData" :config="editorConfig" />
  </div>
  <div class="story-btn">
    <button @click="story">Save Story</button>
  </div>
</div>
</template>

<script>
import { ClassicEditor, Bold, Essentials, Italic, Mention, Paragraph, Undo, Heading} from 'ckeditor5';
 

import 'ckeditor5/ckeditor5.css';
import { mapGetters, mapState } from 'vuex';
import MyFundraiserDetailsCard from '../UI/MyFundraiserDetailsCard.vue';
 

export default {
  name: 'app',
  components: {
    MyFundraiserDetailsCard
  },
  data() {
      return {
          editor: ClassicEditor,
          editorData: '', 
          editorConfig: {
              toolbar: {
                  items: [ 'heading','undo', 'redo', '|', 'bold', 'italic' ],
              },
              heading: {
				options: [
					{
						model: 'paragraph',
						title: 'Paragraph',
						class: 'ck-heading_paragraph'
					},
					{
						model: 'heading1',
						view: 'h1',
						title: 'Heading 1',
						class: 'ck-heading_heading1'
					},
					{
						model: 'heading2',
						view: 'h2',
						title: 'Heading 2',
						class: 'ck-heading_heading2'
					},
					{
						model: 'heading3',
						view: 'h3',
						title: 'Heading 3',
						class: 'ck-heading_heading3'
					},
					{
						model: 'heading4',
						view: 'h4',
						title: 'Heading 4',
						class: 'ck-heading_heading4'
					},
					{
						model: 'heading5',
						view: 'h5',
						title: 'Heading 5',
						class: 'ck-heading_heading5'
					},
					{
						model: 'heading6',
						view: 'h6',
						title: 'Heading 6',
						class: 'ck-heading_heading6'
					}
				]
			},
              plugins: [ Heading, Bold, Essentials, Italic, Mention, Paragraph, Undo ],
          }
      };
  },
  computed: {
    ...mapState({
      singleFund: state => state.singleFund,
      story: state => state.story,
    }),
    ...mapGetters(['singleFund'])
  },
  methods: {
    async story(){
      this.$store.commit('setProcessing', true)        
      const storyData = {
        story: this.editorData
      }
      this.$store.dispatch('uploadStory', storyData);
    }
  }
};
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.fundraiser-story{
  padding: 10px;
  font-size: 30px;
}
.story-btn{
  padding: 10px;
  margin-top: 10px;
  background-color: #FCC82C;
  color: black;
  width:100px;
  border-radius: 20px;
  text-align: center;
}
</style>