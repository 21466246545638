<template>
      <div class="share-section" style="display: flex; justify-content: center; align-items: center; flex-direction: column;padding: 150px 50px;">
        <h2>Share with Friends</h2>
        <div class="social-icons">
          <a @click="share('WhatsApp')" href="#"><img src="@/assets/images/whatsapp.png" alt="WhatsApp" /></a>
          <a @click="share('Facebook')" href="#"><img src="@/assets/images/facebook.png" alt="Facebook" /></a>
          <a @click="share('Twitter')" href="#"><img src="@/assets/images/twitter.png" alt="Twitter" /></a>
        </div>
        <p>Fundraiser Page: <a href="https://www.mchanga.africa/fundraiser/">https://www.mchanga.africa/fundraiser/{{ singleFund.fundraiserId }}</a></p>
        <div class="more-on-whatsapp">
          <button @click="share('WhatsApp - Invite Friends')">Invite Friends</button>
          <button @click="share('WhatsApp - Share Statement')">Share Statement</button>
        </div>
        <!-- <div style="display: flex; justify-content: center; align-items: center; gap: 10px;">
          <button @click="goHome()" style="border: 1px solid #fcc82c; border-radius: 30px; background-color: #fcc82c; width: 150px; height: 40px; margin-top: 10px;">HOME PAGE</button>
          <button @click="goBack()" style="border: 1px solid #fcc82c; border-radius: 30px; background-color: #fcc82c; width: 150px; height: 40px; margin-top: 10px;">Go Back</button>
        </div> -->
      </div>
  </template>
  
  <script>
    import { mapState, mapActions } from 'vuex';

    export default {
      name: 'SharedFundraiser',
      computed: {
        ...mapState(['sharedTo']),
        ...mapActions(['shareToPlatform']),
        ...mapState({
          singleFund: (state) => state.singleFund, 
        })
        // email() {
        //   return 'mwai@spa-limited.com';
        // }
      },
      methods: {
        goBack() {
          this.$router.push("/donate");
        },
        goHome() {
          this.$router.push("/");
        },
        share(platform) {
          this.shareToPlatform(platform);
          console.log(`Shared to ${platform}`);
        }
      }
    };
  </script>

  
  <style scoped>

.share-section {
  margin-top: 20px;
}

.social-icons {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.social-icons a img {
  width: 32px;
  height: 32px;
}

.more-on-whatsapp button {
  margin-right: 10px;
}
  
</style>