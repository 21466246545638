<template>
  <div>
    <MyFundraiserDetailsCard :fundraiser="fundraiser"></MyFundraiserDetailsCard>
    <div class="feedback-form">
      <h1>M-Changa Customer Feedback Survey</h1>
      <p>
        We value your feedback. Please assist us with the following questions. This will help us understand your needs and improve our services.
      </p>
      <form @submit.prevent="handleSubmit">
        <div class="form-group">
          <label>1. How likely are you to recommend M-Changa to your friends, family, and colleagues?</label>
          <div class="rating">
            <label><input type="radio" name="recommend" value="1" v-model="recommend" /> 1</label>
            <label><input type="radio" name="recommend" value="2" v-model="recommend" /> 2</label>
            <label><input type="radio" name="recommend" value="3" v-model="recommend" /> 3</label>
            <label><input type="radio" name="recommend" value="4" v-model="recommend" /> 4</label>
            <label><input type="radio" name="recommend" value="5" v-model="recommend" /> 5</label>
          </div>
        </div>
        <div class="form-group">
          <label>2. What do you most like about the M-Changa Fundraising Platform? (optional)</label>
          <textarea rows="3" v-model="mostLiked"></textarea>
        </div>
        <div class="form-group">
          <label>3. What would make your experience with M-Changa even better? (optional)</label>
          <textarea rows="3" v-model="improvement"></textarea>
        </div>
        <div class="form-group">
          <label>4. What advice would you give to anyone using M-Changa for the first time? (optional)</label>
          <textarea rows="3" v-model="advice"></textarea>
        </div>
        <div class="form-group">
          <label>5. Any additional comments? (Optional)</label>
          <textarea rows="3" v-model="additionalComment"></textarea>
        </div>
        <button type="submit">Send Feedback</button>
      </form>
    </div>
    </div>
  </template>
  
  <script>
  import { mapState, mapActions } from 'vuex';
  import MyFundraiserDetailsCard from '@/components/UI/MyFundraiserDetailsCard.vue';
  
  export default {
    name: 'FeedbackForm',
    components: {
        MyFundraiserDetailsCard,
    },
    computed: {
      ...mapState(['fundraiser'])
    },
    methods: {
      ...mapActions(['submitFeedback']),
      handleSubmit() {
        this.submitFeedback(this.feedback);
      }
    },
  };
  </script>
  
  <style scoped>
  .feedback-form {
    padding: 20px;
  }
  
  .feedback-form h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .feedback-form p {
    font-size: 14px;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .rating label {
    margin-right: 10px;
  }
  
  button {
    padding: 10px 20px;
    background-color: #28a745;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  </style>
  