<template>
  <div class="activate-wrapper" style="display: flex; padding: 30px 50px; justify-content: center; align-items: center;" >
    <form @submit="activate"  style="display: flex; width: 700px; margin-top: 10%; justify-content: center; align-items: center; flex-direction: column;background: rgba(240, 240, 240, 0.9);backdrop-filter: blur(5px);">
      <div  style="background-color: #fcc82c; height: 50px; font-size: 25px; width: 100%; display: flex; align-items: center; justify-content: center;">      
        <h1>Fundraiser Activation</h1>
      </div>
      <div style="font-size: 20px;justify-content: center; align-items: center; text-align: center; margin-bottom: 10px;">      
        <p>Enter your Mobile Number below to activate your fundraiser</p>
        <p>A prompt will be sent to the Mobile Number</p>
      </div>
        <input style="width: 80%;padding: 8px; margin-bottom: 12px;font-size: 1em;border: 1px solid #ccc;border-radius: 20px; outline: none; transition: border-color 0.3s;" v-model="mobileNumber" type="text" placeholder="+254" required>
        <button class="activation-button" @click="activate">ACTIVATE</button>
      </form>
  </div>
</template>

<script>
import BASE_URL from '@/config';
import axiosInstance from '@/instance';

export default {
    name: 'FundraiserActivation',
    data() {
      return{
        mobileNumber: '',
        loading: false
      }
    },
    methods: {
      async activate(event) {
        event.preventDefault();
        this.loading = true;
        axiosInstance.post(BASE_URL+`/api/fundraiser/activate`, this.mobileNumber, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(async(res)=>{
          console.log("see number =>",res)
          if(res.data.success) {
            console.log('Mobile number is here');
            window.location.href =`/dashboard`;      
          }else{
            console.log("errrr")
          }
          this.loading = false;
        })
        .catch(error => {
          console.error("Fundraiser Activation failed",error);
          this.loading = false;
        });
      }
    }, 
    mounted() {
    this.interval = setInterval(this.activate, 10000); // Check every 10 seconds
    },
    beforeUnmount() {
      clearInterval(this.interval);
    },
};
</script>

<style scoped>

.activation-button {
  border: 1px solid #fcc82c; 
  border-radius: 20px; 
  width: 100px; 
  height: 30px; 
  margin: 10px; 
  background-color: #fcc82c; 
  font-weight: bold;
}

.activation-button:hover {
  background-color: black;
  color: #ccc;
  border: none;
}
</style>

  