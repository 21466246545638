<template>
    <div class="homepage-carousel">
        <div class="fundraiser-carousel" v-for="fund in fundraisers" :key="fund.fundraiserId"> 
            <img class="carousel-image" src="@/assets/images/logo.png" alt="Smart People Africa">
            <div class="fundraiser-details">
                <h2>{{ fund.title }}</h2>
                <p>Raised: KSh{{ fund.raisedAmount }} </p>
                <p>Donors:{{ fund.targetAmount }}</p>
                <button>DONATE</button>
            </div>  
        </div>
    </div>

</template>


<script>
import { mapGetters, mapState } from 'vuex';

export default {
    name: 'HomepageCarousel',
    computed: {
        ...mapState({
            fundraisers: state => state.fundraisers
        }),
        ...mapGetters(['fundraisers','fundraiser'])
    },
    created() {
      this.$store.dispatch('fetchFundraisers');   
    },
}
</script>


<style scoped>
.homepage-carousel{
    display: flex;
    gap: 10px;
}
.fundraiser-carousel{
  display: flex;
  justify-content: center; 
    display:flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}
.carousel-image {
    height: 100px;
    width:100px;
    object-fit: contain;
}
  .fundraiser-details {
  border: 1px solid #ddd;
    border-radius: 8px;
    display: flex; 
    padding: 0px;
    background-color: #ffffff;
    height: 300px;
    width: 400px;
    text-align: center;
    flex-direction: column;
}
.fundraiser-details h2 {
    margin-top: 50px;
  font-size: 1.5rem;
}

.fundraiser-details p {
  margin: 5px 0;
  font-size: 1rem;
}
.fundraiser-details button{
    background-color: #fff;
    color: #070606;
    border: 1px solid #cc8400;
    border-radius: 20px;
    margin-top: 70px;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    height: 30px;
}
.fundraiser-details button:hover{
    background-color: #070606;
    color: white;
    border: none;
}
</style>