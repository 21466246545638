<template>
  <div>
    <MyFundraiserDetailsCard></MyFundraiserDetailsCard>
    <div class="withdraw-funds">
      <h2>Withdraw Funds</h2>
      <div v-if="!fundraiserVerified" class="alert alert-warning">
        Your fundraiser is not yet Activated! Please add 3 Treasurers to secure your account. 
        <RouterLink to="/treasurers" class="add-treasurers-link">Add treasurers</RouterLink>
      </div>
      <div v-if="!fundraiserVerified" class="alert alert-danger">
          Sorry! Your fundraiser is not active. Please call support at +254705484192 or email support@spa-limited.com for help.
      </div>
        <div>
        <div style="display: flex; justify-content:center; align-items:center; flex-direction: column; padding-top: 10px">
            <h2 style=" width: 100%;text-align:center; font-size:30px;" >Withdraw Funds</h2>
            <h3>Enter your mobile details below.</h3>
            <form @submit.prevent="withdrawFunds">
                <div>
                    <input style="width: 600px; height: 30px; border: 1px solid #c5c5c5; border-radius: 20px; padding: 20px; margin-top: 10px;"  v-model="mobileNumber" type="text" placeholder="07" required>
                </div>
            </form>
        </div>
    </div>
        <button @click="withdrawFunds" type="submit" class="btn btn-primary" :disabled="!fundraiserVerified">Submit</button>
    </div>
    </div>
  </template>
  
  <script>
  import { mapState, mapGetters } from 'vuex';
  import MyFundraiserDetailsCard from '../UI/MyFundraiserDetailsCard.vue';

  export default {
    name: 'WithdrawFunds',
    components: {
      MyFundraiserDetailsCard
    },
    data() {
      return {
        mobileNumber: "+254",
      };
    },
    computed: {
      ...mapState({
        singleFund: state => state.singleFund,
      }),
      ...mapGetters(['singleFund']),
      fundraiserVerified() {
        return this.singleFund.status === 'ACTIVE';
      }
    },
    methods:{
      async withdrawFunds(){
        this.$store.commit('setProcessing', true)        
        const withdrawDetails = {
          fundraiserId: this.singleFund.fundraiserId,
          withdrawAmount: this.withdrawAmount,
          mobileNumber: this.mobileNumber
        }
        // console.log('Creating request:', )
        this.$store.dispatch('withdrawFunds', withdrawDetails);
      }
    }

  };

  </script>
  
  <style scoped>
  .withdraw-funds {
    padding: 20px;
  }
  
  .alert {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
  }
  
  .alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }
  
  .alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }
  
  .add-treasurers-link {
    color: #0056b3;
    text-decoration: underline;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .radio-group {
    display: flex;
    flex-direction: column;
  }
  
  .radio-group label {
    margin-bottom: 5px;
  }
  
  .bank-details-form {
    margin-top: 20px;
  }
  
  .bank-details-form h3 {
    margin-bottom: 15px;
  }
  
  textarea, input {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  textarea {
    height: 100px;
  }
  
  .btn {
    padding: 10px 20px;
    color: #fff;
    background-color: #007bff;
    border: none;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .btn-secondary {
    background-color: #6c757d;
    color: #fff;
  }
  
  .btn-primary:disabled {
    background-color: #6c757d;
  }
  </style>
  