import getters from "./modules/auth/getters";
import mutations from "./modules/auth/mutations";
import actions from "./modules/auth/actions";
import Vuex from 'vuex';
import auth from './modules/auth';
import createPersistedState from 'vuex-persistedstate';


export default new Vuex.Store({
  modules: {
    auth,
  },
    state: {
        countries: null,
        processing: false,
        fundraisers: [],
        fundraiserCard: [],
        users: [],
        user: {},
        fundraiser:{},
        refreshToken: '',
        accessToken:'',
        singleFund:{},
        donations:{}, 
        file:{},
        patrons:[],
        patron:{},
        treasurers:[],
        treasurer:{},
        story: {},
        withdraws: [],
        withdraw: {},
        logOut: {}
    },
    plugins: [
      createPersistedState({
        key: 'vuex',
        paths: ['user','fundraiser', 'singleFund'], // Only persist the user state
        storage: window.localStorage, // Store in localStorage
      })
    ],
    mutations,
    getters,
    actions,
});