<template>
  <div>
      <div class="tabs">
        <button :class="{'active': activeTab === 'donations'}" @click="activeTab = 'donations'">Donations</button>
        <button :class="{'active': activeTab === 'withdrawals'}" @click="activeTab = 'withdrawals'">Withdrawals</button>
        <button :class="{'active': activeTab === 'expenses'}" @click="activeTab = 'expenses'">Expenses</button>
      </div>
      <div class="tab-content">
        <div v-if="activeTab === 'donations'">
          <h3>Latest Donations</h3>
          <p class="note">NB: In compliance with the Kenyan data protection laws, personal information on your statements will be truncated.</p>
          <div class="download-buttons">
            <button class="btn btn-pdf" @click="downloadPDF">PDF</button>
          <button class="btn btn-excel" @click="downloadExcel">Excel</button>
          <button class="btn btn-whatsapp" @click="shareOnWhatsApp">WhatsApp</button>
          </div>
          <table class="donations-table">
            <thead>
              <tr>
                <th>Donor Name</th>
                <th>Contact</th>
                <th>Amount (KES)</th>
                <th>Trans Ref</th>
                <th>Donation Channel</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="donation in donations" :key="donation.donationId">
                <td>{{ donation.donor }}</td>
                <td>{{ truncate(donation.donorContact) }}</td>
                <td>{{ donation.amountDonated }}</td>
                <td>{{ truncate(donation.donorTransactionReference) }}</td>
                <td>{{ donation.paymentMethod }}</td>
                <td>{{ donation.date }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="activeTab === 'withdrawals'">
          <h3>Withdrawals</h3>
          <p class="note">NB: In compliance with the Kenyan data protection laws, personal information on your statements will be truncated.</p>
          <div class="download-buttons">
            <button class="btn btn-pdf" @click="downloadPDF">PDF</button>
          <button class="btn btn-excel" @click="downloadExcel">Excel</button>
          <button class="btn btn-whatsapp" @click="shareOnWhatsApp">WhatsApp</button>
          </div>
          <table class="withdrawals-table">
            <thead>
              <tr>
                <th>Amount (KES)</th>
                <th>Fees (KES)</th>
                <th>Transaction Reference</th>
                <th>Total (KES)</th>
                <th>Type</th>
                <th>Status</th>
                <th>Balance (KES)</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="withdraw in withdraws" :key="withdraw.withdrawId">
                <td>{{ withdraw.withdrawAmount }}</td>
                <td>{{ withdraw.withdrawFees }}</td>
                <td>{{truncate( withdraw.withdrawTransactionReference )}}</td>
                <td>{{ withdraw.total }}</td>
                <td>{{ withdraw.withdrawType }}</td>
                <td>{{ withdraw.withdrawStatus }}</td>
                <td>{{ withdraw.balance }}</td>
                <td>{{ withdraw.date }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="activeTab === 'expenses'">
          <h3>Expenses</h3>
          <p class="note">NB: In compliance with the Kenyan data protection laws, personal information on your statements will be truncated.</p>
          <div class="download-buttons">
            <button class="btn btn-pdf" @click="downloadPDF">PDF</button>
          <button class="btn btn-excel" @click="downloadExcel">Excel</button>
          <button class="btn btn-whatsapp" @click="shareOnWhatsApp">WhatsApp</button>
          </div>
          <table class="expenses-table">
            <thead>
              <tr>
                <th>Expense</th>
                <th>Transaction Reference</th>
                <th>Amount (KES)</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="expense in expenses" :key="expense.expenseId">
                <td>{{ expense.expense }}</td>
                <td>{{ truncate(expense.expenseTransactionReference) }}</td>
                <td>{{ expense.expenseAmount }}</td>
                <td>{{ expense.date }}</td>
              </tr>
            </tbody>
          </table>
        </div>
    </div>
  </div>
</template>


<script>
 import { mapState, mapGetters } from 'vuex';
  import jsPDF from 'jspdf';
  import autoTable from 'jspdf-autotable';
  import* as XLSX from 'xlsx';

  export default {
    name: 'AllUserStatements',
    data() {
      return {
        activeTab: 'donations'
      };
    },
    computed: {
      ...mapState({
      fundraisers: (state) => state.fundraisers, 
    }),
    ...mapGetters(['fundraisers', 'processing', 'user']),
    },
    methods: {
      truncate(str) {
        if (!str) return '';
        return str.length > 4 ? `${str.substring(0, 4)}****` : str;
        // return text.length > 6 ? text.substring(0, 6) + '...' : text;
      },
      downloadPDF() {
        const activeTable = this.getActiveTableSelector();
        if (activeTable) {
          const doc = new jsPDF();
          autoTable(doc, { html: activeTable });
          doc.save(`${this.activeTab}.pdf`);
        }
      },
      downloadExcel() {
        const activeTable = this.getActiveTableSelector();
        if (activeTable) {
          const table = document.querySelector(activeTable);
          const workbook = XLSX.utils.table_to_book(table);
          XLSX.writeFile(workbook, `${this.activeTab}.xlsx`);
        }
      },
      getActiveTableSelector() {
        switch (this.activeTab) {
          case 'donations':
            return '.donations-table';
          case 'withdrawals':
            return '.withdrawals-table';
          case 'expenses':
            return '.expenses-table';
          default:
            return null;
          }
        },
      shareOnWhatsApp() {
        const url = 'https://api.whatsapp.com/send?text=' + encodeURIComponent(window.location.href);
        window.open(url, '_blank');
      },
   },
  };
</script>

<style scoped>
.tabs {
    display: flex;
    margin: 20px 0;
  }
  
  .tabs button {
    background: #f1f1f1;
    border: 1px solid #ccc;
    padding: 10px;
    cursor: pointer;
  }
  
  .tabs button.active {
    background: #fff;
    border-bottom: 2px solid #007bff;
  }
  
  .tab-content {
    margin-top: 20px;
  }
  
  .donations-table, .withdrawals-table, .expenses-table{
    width: 100%;
    border-collapse: collapse;
  }
  
  .donations-table th,
  .donations-table td,
  .withdrawals-table th, 
  .withdrawals-table td, 
  .expenses-table th, 
  .expenses-table {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  .donations-table th,
  .donations-table td,
  .withdrawals-table th, 
  .withdrawals-table td, 
  .expenses-table th, 
  .expenses-table {
    background-color: #f8f9fa;
    font-weight: bold;
  }
  .note {
    font-size: 12px;
    color: #6c757d;
    margin-bottom: 10px;
  }
  
  .download-buttons {
    margin-bottom: 10px;
  }
  
  .download-buttons .btn {
    margin-right: 10px;
    border-radius: 10px;
    width: 70px;
  }
  
  .btn-pdf {
    background-color: #d9534f;
    color: #fff;
  }
  
  .btn-excel {
    background-color: #5cb85c;
    color: #fff;
  }
  
  .btn-whatsapp {
    background-color: #25d366;
    color: #fff;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
</style>