import { createRouter, createWebHistory } from 'vue-router';
import HomePage from './components/HomePage.vue';
import LoginPlace from './components/LoginPlace.vue';
import RegisterView from './components/RegisterView.vue';
import FundraiserActivation from './components/FundraiserActivation.vue';
import SharedFundraiser from './components/UsersView/SharedFundraiser.vue';
import FundraiserDetail from './components/UI/FundraiserDetail.vue';
import FundraiserList from './components/UI/FundraiserList.vue';
import WithdrawMoney from './components/UsersView/WithdrawMoney.vue';
import FundCarousel from './components/UI/FundCarousel.vue'; 
import TheResources from './components/TheResources.vue';
import HomePageHeader from './components/UI/HomePageHeader.vue';
import HomepageCarousel from './components/UI/HomepageCarousel.vue';
import CarouselTrial from './components/UI/CarouselTrial.vue';
import DonationView from './components/DonationView.vue';
import MyFundraiserDetailsCard from './components/UI/MyFundraiserDetailsCard.vue'; 
import DashBoard from './components/UsersView/DashBoard.vue';
import LayOut from './components/LayOut.vue';
import UserSummary from './components/UsersView/UserSummary.vue';
import MyStatement from './components/UsersView/MyStatement.vue';
import AllUserStatements from './components/UsersView/AllUserStatements.vue';
import DocumentUpload from './components/UsersView/DocumentUpload.vue';
import FundraiserCustodians from './components/UsersView/FundraiserCustodians.vue';
import UpdateFundraiser from './components/UsersView/UpdateFundraiser.vue';
import FundraiserStory from './components/UsersView/FundraiserStory.vue';
import ImageUpload from './components/UsersView/ImageUpload.vue';
import VideoUpload from './components/UsersView/VideoUpload.vue';
import FundraiserPatron from './components/UsersView/FundraiserPatron.vue';
import FeedbackForm from './components/UsersView/FeedbackForm.vue';
import AddFundraiser from './components/UsersView/AddFundraiser.vue';

const routes = [

  {
    path: '/dash',
    component: LayOut,  
    children: [
      { path: '/dash', name: 'DashBoard', component: DashBoard, props: true },
      { path: '/withdraw', name: 'WithdrawMoney', component: WithdrawMoney, props: true },
      { path: '/summary', name: 'UserSummary', component: UserSummary, props: true },
      { path: '/statement', name: 'MyStatement', component: MyStatement, props: true },
      { path: '/AllUserStatements', name: 'AllUserStatements', component: AllUserStatements, props: true },
      { path: '/documents', name: 'DocumentUpload', component: DocumentUpload, props: true },
      { path: '/treasurers', name: 'FundraiserCustodians', component: FundraiserCustodians, props: true },
      { path: '/details', name: 'UpdateFundraiser', component: UpdateFundraiser, props: true },
      { path: '/story', name: 'FundraiserStory', component: FundraiserStory, props: true },
      { path: '/images', name: 'ImageUpload', component: ImageUpload, props: true },
      { path: '/video', name: 'VideoUpload', component: VideoUpload, props: true },
      { path: '/patrons', name: 'FundraiserPatron', component: FundraiserPatron, props: true },
      { path: '/feedback', name: 'FeedbackForm', component: FeedbackForm },
      { path: '/addFundraiser', name: 'AddFundraiser', component: AddFundraiser }, 
      { path: '/share', name: 'SharedFundraiser', component: SharedFundraiser },
    ],
   
  },

  { path: '/', name: 'HomePage', component: HomePage },
  { path: '/login', component: LoginPlace },
  { path: '/register', component: RegisterView },
  { path: '/resources', component: TheResources },
  { path: '/homepageheader', component: HomePageHeader},
  { path: '/carousel', component: HomepageCarousel},
  { path: '/trial', component: CarouselTrial},
  { path: '/donate', component: DonationView},
  { path: '/details', component: MyFundraiserDetailsCard},
  // { path: '/dash', component: DashBoard},
  { path: '/activate', component: FundraiserActivation },
  { path: '/carousel', component: FundCarousel },
    //{ path: '/payment-status', component }
  { path: '/fundraisers', component: FundraiserList, children: [
    { path: '/fundraisers/:fundraiserId', component: FundraiserDetail, props: true}
  ] },
];  


const router = createRouter({
  history: createWebHistory(),
  routes,
});
    // meta: { requiresAuth: true }

// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     if (!store.getters.isAuthenticated) {
//       next({ path: '/' });
//     } else {
//       next();
//     }
//   } else {
//     next();
//   }
// });
export default router;
