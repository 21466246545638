<template>
  <div class="homepage-carousel" @mouseenter="pauseCarousel" @mouseleave="resumeCarousel">
    <div class="carousel-container">
      <div
        class="fundraiser-carousel"
        v-for="fund in visibleFundraisers"
        :key="fund.fundraiserId"
      >
        <img class="carousel-image" src="@/assets/images/logo.png" alt="Smart People Africa" />
        <div class="fundraiser-details">
          <h2>{{ fund.title }}</h2>
          <p>Raised: KSh {{ fund.raisedAmount }}</p>
          <p>Target Amount: {{ fund.targetAmount }}</p>
          <button class="donate-btn" @click="goToFundraiser(fund)">DONATE</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "CarouselTrial",
  data() {
    return {
      currentIndex: 0,
      intervalId: null,
    };
  },
  computed: {
    ...mapState({
      fundraisers: (state) => state.fundraisers,
    }),
    visibleFundraisers() {
      // Display 3 fundraisers at a time
      return this.fundraisers.slice(this.currentIndex, this.currentIndex + 3);
    },
  },
  created() {
    this.$store.dispatch('fetchFundraisers', 'fetchFundraiserById');
    this.startCarousel();
  },
  methods: {
    goToFundraiser(fund) {
      this.$store.commit('setSingleFund', fund)
       this.$router.push({
        path: "/donate",
      });
    },
    startCarousel() {
      // Auto-scroll every 1.5 seconds
      this.intervalId = setInterval(() => {
        this.nextSlide();
      }, 1500);
    },
    nextSlide() {
      // Move to the next set of fundraisers
      if (this.currentIndex + 3 >= this.fundraisers.length) {
        this.currentIndex = 0; // Loop back to the beginning
      } else {
        this.currentIndex += 3;
      }
    },
    pauseCarousel() {
      clearInterval(this.intervalId);
    },
    resumeCarousel() {
      this.startCarousel();
    },
  },
  beforeMount() {
    clearInterval(this.intervalId);
  },
};
</script>

<style scoped>
.homepage-carousel {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.carousel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-grow: 1;
  padding: 10px;
  margin-bottom: 10px;
  gap: 20px;
  transition: transform 0.5s ease-in-out;
}

.fundraiser-carousel {
  flex: 0 0 30%; /* Make each fundraiser take up 30% of the width (for 3 per view) */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.carousel-image {
  height: 100px;
  width: 100px;
  object-fit: contain;
}

.fundraiser-details {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  background-color: #ffffff;
  width: 100%;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.fundraiser-details h2 {
  font-size: 1.5rem;
}

.fundraiser-details p {
  margin: 5px 0;
  font-size: 1rem;
}

.fundraiser-details button {
  background-color: #fff;
  color: #070606;
  border: 1px solid #cc8400;
  border-radius: 20px;
  width: 90%;
  height: 30px;
}

.fundraiser-details button:hover {
  background-color: #070606;
  color: white;
  border: none;
}
</style>
