import axiosInstance from '@/instance';
import axios from 'axios';
import BASE_URL from '@/config';


export default{
    async register({ commit }, user) {
        try {
          let regUrl = BASE_URL+'/api/fundraiser/create-user';
          const response = await axios.post(regUrl,user );
          commit('setFundraiser', response.data);
        } catch (error) {
          console.error('Error registering:', error);
          throw error;
        }
    },
    async createFundraiser(context,newFundraiser) {
      console.log('Create Fundraiser function called', newFundraiser);
      try{
        console.log('Create Fundraiser function called', newFundraiser);
        const response = await axiosInstance.post(BASE_URL+`/api/fundraiser/create`, newFundraiser);
        console.log('API Response for creation:', response);
        if(response.data.success) { 
          return response;
        }else{
          console.log("Error: API Call unsuccessful");
          return response;
        }
      }catch(error) {
        console.log("Error in creating fundraiser:", error.message);
        throw error;
      }
    },
      async fetchFundraisersByEmail({ commit, state }) {
        try {
          if (!state.user || !state.user.sub) {
            throw new Error("User information is not available in the state");
          }
          commit('setProcessing', true);
          const response = await axiosInstance.get(BASE_URL+`/api/fundraiser/by-email?userEmail=${state.user.sub}`);
          if (response.data.success) {
            commit('setFundraiserCard', response.data.data.result);
          } else {
            console.log("Error: API call unsuccessful");
          }
        } catch (error) {
          console.log("Error in fetchFundraisersByEmail:", error.message);
        } finally {
          commit('setProcessing', false);
        }
      },
      async fetchFundraisers({ commit }) {
        try {
          console.log("fetching fundraisers")
          const response = await axios.get(BASE_URL+'/api/fundraiser');
          console.log("API response for fetching all fundraisers",response);
          if(response.data.success) {
            commit('setFundraisers', response.data.data.result);
          }else {
            console.log("Error: API call unsuccessful");
          }
        } catch (error) {
          console.error('Error fetching fundraisers:', error);
        }
      },
      async fetchFundraiserById({state}) {
        try {
          const response = await axiosInstance.get(BASE_URL+`/api/fundraiser/by-fundraiserId?fundraiserId=${state.singleFund.fundraiserId}`);
          if(response.data.success) {
            return response;
          }else {
            return response;
          }
        } catch (error) {
          console.error('Error fetching fundraiser:', error);
        }
      },
      async uploadFile({ commit }, formData){
        axios.post(BASE_URL+'/api/images/upload', formData)
        .then(async(res)=>{
          console.log("seee file", res)
          if(res.data.success) {
            console.log("file", res.data.data);
            commit('setImage', res.data.data.result);
            commit('setProcessing', false);
          }else{
            console.log("Error: API call unsuccessful");
            commit('setProcessing', false);
          }
        })
      },
      async uploadStory({commit}, storyData){
        axiosInstance.post(BASE_URL+`/api/story/upload`,storyData)
        .then(async(res)=>{
          console.log("story uploaded", res)
          if(res.data.success) {
            console.log("story",res.data.data);
            commit('setStory', res.data.data);
            commit('setProcessing', false);
          }else{
            console.log("Error: API call unsuccessful");
            commit('setProcessing', false);
          }
        })
      },
      async uploadVideo({commit}, videoData){
        axiosInstance.post(BASE_URL+`/api/video/upload`, videoData)
        .then(async(res)=>{
          console.log("video uploaded", res)
          if(res.data.success){
            console.log("video",res.data.data);
            commit('setVideo', res.data.data)
            commit('setProcessing', false);
          }else{
            console.log("Error: API call unsuccessful");
            commit('setProcessing', false);
          }
        })
      },
      async invitePatron({commit}, patronData){
        try{
          const response = await axiosInstance.post(BASE_URL+'/api/images/upload', patronData);
          if(response.data.success) {
            console.log("patron",response.data.data.result);
            commit('setPatron', response.data.data.result);
            commit('setProcessing', false)        
          } else {
            commit('setProcessing', false)        
            console.log("Error: API call unsuccessful");
          }
        }catch (error) {
            commit('setProcessing', false)        
            console.error('Error inviting patron:', error);
          }
      },
      async logout({ commit }){
        commit('setLogout');
      },
      async withdrawFunds({commit}, withdrawDetails){
        axiosInstance.post(BASE_URL+`/api/v3/withdraw`, withdrawDetails)
        .then(async(res)=>{
          console.log("see number =>",res)
          if(res.data.success) {
            console.log('Mobile number is here', res.data.data.result);
            commit('setProcessing', false) 
            commit('setWithdraw', res.data.data.result)       
          }else{
            commit('setProcessing', false)        
            console.log("errrrr")
          }
          commit('setProcessing', false)        
        })
        .catch(error => {
          console.error("Withdraw of funds failed", error);
          commit('setProcessing', false)        
        })
      },

      searchFundraiser({ commit, state }, query) {
        console.log("fundraiser is searching")
        const results = state.fundraisers.filter(fundraiser =>
          fundraiser.title.toLowerCase().includes(query.toLowerCase())
        );
        commit('setSearchResults', results);
      },
       async fetchDonationsById({ commit }, fundraiserId) {
        try {
          const response = await axiosInstance.get(BASE_URL+`/auth/api/donations/${fundraiserId}`);
          // Check if the response contains tokens and success
        if (response.data.success) {
          // Commit the access and refresh tokens
          commit('setToken', response.data.access_token);
          commit('setRefreshToken', response.data.refresh_token);
        }
          commit('setDonations', response.data);
        } catch (error) {
          console.error('Error fetching donations:', error);
        }
      },
      async fetchDonations({ commit }) {
        try {
          const response = await axios.get(BASE_URL+`/api/donations`);
          commit('setDonations', response.data.fundraiser);
        } catch (error) {
          console.error('Error fetching donations:', error);
        }
      },
      async submitFeedback({ state }, feedback) {
        try {
          await axiosInstance.post(BASE_URL+`/api/fundraisers/${state.fundraiser.id}/feedback`, feedback);
          alert('Feedback submitted successfully');
        } catch (error) {
          console.error('Error submitting feedback:', error);
          alert('There was an error submitting your feedback');
        }
      },
};