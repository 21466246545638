export default{
      setUser(state,user) {
        state.user = user;
      },
      setUsers(state,users) {
        state.users = users;
      },
      setFundraiser(state,fundraiser) {
        state.fundraiser = fundraiser;
      },
      setFundraisers(state, fundraisers) {
        state.fundraisers = fundraisers
      },
      setFundraiserCard(state, fundraiserCard) {
        state.fundraiserCard = fundraiserCard;
      },
      setRefreshToken(state, refreshToken) {
        state.refreshToken = refreshToken;
      },
      setAccessToken( state, accessToken) {
        state.accessToken = accessToken;
      },
      setSingleFund(state, singleFund){ 
        state.singleFund = singleFund;
      },
      setDonations(state, donations){
        state.donations = donations;
      },
      setFile(state, file){
        state.file = file;
      },
      setLogout(state, logout){
        state.logout = logout;
      },
      setProcessing(state, processing){
        state.processing = processing;
      },
      setTreasurers(state, treasurers){
        state.treasurers = treasurers;
      },
      setTreasurer(state, treasurer){
        state.treasurer = treasurer;
      },
      setStory(state, storyText){
        state.story = storyText;
      },
      setWithdraw(state, withdraw){
        state.withdraw = withdraw;
      },
      setWithdraws(state, withdraws){
        state.withdraws = withdraws;
      }
    };