<template>

<footer class="footer">
      <div class="container">
          <div class="footer-section">
            <img src="@/assets/images/logo.png" alt="Company Logo" class="footer-logo">
          </div>
      </div>
        <div class="footer-content">
          <div>
              <span>
                  <!-- <img src="@/assets/images/logo.png" alt="Company Logo" class="footer-logo"> -->
                  <p>Address: 9401 - 00200 Nairobi  <br></p>
                  <p><br>Sultan Office Suites,<br>Opposite KCB Leadership Center, <br> Karen, Nairobi.</p>
              </span>
              <span>
                <div class="social-icons">
                  <a href="https://www.facebook.com" target="_blank">
                    <img src="@/assets/images/facebook.webp" alt="Facebook" />
                  </a>
                  <a href="https://www.twitter.com" target="_blank">
                    <img src="@/assets/images/twitter.webp" alt="Twitter" />
                  </a>
                  <a href="https://www.linkedin.com" target="_blank">
                    <img src="@/assets/images/linkedin.webp" alt="LinkedIn" />
                  </a>
                  <a href="https://www.youtube.com" target="_blank">
                    <img src="@/assets/images/youtube.webp" alt="YouTube" />
                  </a>
                  <a href="https://www.instagram.com" target="_blank">
                    <img src="@/assets/images/instagram.webp" alt="Instagram" />
                  </a>
                </div>
              </span>
          </div>
            <div>
              <!-- <h3>Menu</h3>
                <ul>
                  <li><a href="#">About</a></li>
                  <li><a href="#">Questions</a></li>
                  <li><a href="#">Resources</a></li>
                  <li><a href="#">USSD Fundraising</a></li>
                  <li><a href="#">Online Fundraising</a></li>
                  <li><a href="#">Champions</a></li>
                  <li><a href="#">Communities</a></li>
                  <li><a href="#">M-Changa Communities</a></li>
                  <li><a href="#">M-Changa Fees</a></li>
                </ul> -->
              <p>Phone: +254 724 985 933 <br> +254 796 855 931</p>
              <p>Email Address:<br> info@spa-limited.com</p></div>
            <div>          
              <p><a href="www.spa-limited.com">www.spa-limited.com</a></p>
            </div>
        </div>
        <div class="footer-bottom">
          <p>© {{ currentYear }} SPA-Fundraiser. All Rights Reserved</p>
          <p><a href="#">Privacy Policy</a> | <a href="#">Terms of use</a></p>
        </div>
    </footer>
</template>

<script>
    export default {
        name: "HomePageHeader",
        computed: {
            currentYear() {
                return new Date().getFullYear();
            }
        },
    };

</script>


<style>
.footer {
    background-color: #011d13;
    color: #ffffff;
    padding: 15px 0;
    font-family: museo_sans_rounded900;
    width: 100%;
}

.container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
}

.footer-section {
    flex: 1;
    padding: 20px;
}

.footer-content{
    display: flex; 
    justify-content: space-between;
    padding: 30px 70px;
}

/* .footer-section .menu {
    display: flex;
    flex-direction: column;
} */

.footer-logo {
    margin-top: 10px;
    width: 150px;
    margin-bottom: 0%;
    align-content: center;
    align-items: flex-start;
    color: #ffffff;
}

.social-icons {
    margin-top: 20px;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    flex-direction: row;
    }

.social-icons a {
    color: #ffffff;
    margin-right: 10px;
    font-size: 20px;
    display: flex;
}
/* .menu ul {
    list-style: none;
    padding: 0;
}

.menu ul li {
    margin-bottom: 10px;
}

.menu ul li a {
    color: #ffffff;
    text-decoration: none;
}

.menu ul li a:hover {
    text-decoration: underline;
} */

.footer-content a {
    color: #ffffff;
    text-decoration: none;
}

.footer-content a:hover {
    text-decoration: underline;
}

.footer-bottom {
    text-align: center;
    padding-top: 20px;
    margin-top: 30px;
    display: flex;
    align-items: flex-start;
    height: fit-content;
    background-color: #011d13;
    border-top: 1px solid hsla(0, 0%, 100%, .2);
    justify-content: space-between;
    padding: 0 50px;
}

.footer-bottom p {
    margin: 5px 0;
}

.footer-bottom a {
    color: #ffffff;
    text-decoration: none;
    margin-right:10px;
    flex-direction: row;
    margin: 5px 0;
}

.footer-bottom a:hover {
    text-decoration: underline;
}
</style>