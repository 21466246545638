<template>
    <MyFundraiserDetailsCard :fundraiser="fundraiser"></MyFundraiserDetailsCard>
    <div class="document-upload">
      <h2>Upload Your Documents</h2>
      <form @submit.prevent="uploadFiles">
        <div>
          <input type="file" id="documentId" @change="uploadFile" accept=".pdf" required />
        </div>
        <button type="submit">Upload Documents</button>
      </form>
    </div>
  </template>
  
  <script>
  import { mapState } from 'vuex';
  import MyFundraiserDetailsCard from '../UI/MyFundraiserDetailsCard.vue';

  export default {
    name: 'DocumentUpload',
    components: {
      MyFundraiserDetailsCard
    },
    data() {
        return {
          selectedFile: null,
      };
    },
    computed: {
      ...mapState({
        singleFund: state => state.singleFund,
        file: state => state.file
      }),
    },
    methods: {
      async uploadFile(event) {
        this.$store.commit('setProcessing', true)        
        console.log("hellooooooooooo",event.target.files[0]);
        const formData = new FormData();
        formData.append("file", event.target.files[0]);
        console.log("formData file:", formData.get('file'));
        await this.$store.dispatch('uploadFile', formData);
      }
    }
  }
  </script>
  
  <style scoped>
  .document-upload {
    flex-grow: 1;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  label {
    display: block;
    margin-bottom: 10px;
  }
  
  input[type="file"] {
    display: block;
    margin-bottom: 20px;
  }
  
  button {
    padding: 10px 20px;
    color: black;
    background-color: #FCC82C;
    border: none;
    cursor: pointer;
    border-radius: 20px;
  }
  
  button:hover {
    background-color: #cc8400;
  }
  </style>
  