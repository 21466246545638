export default{
    user: (state) => state.user,
    users: (state) => state.users,
    fundraiser: (state) => state.fundraiser,
    fundraisers: (state) => state.fundraisers,
    fundraiserCard: (state) => state.fundraiserCard,
    processing: (state) => state.processing,
    accessToken: (state) => state.accessToken,
    refreshToken: (state) => state.refreshToken,
    singleFund: (state) => state.singleFund,
    donations: (state) => state.donations,
    file: (state) => state.file,
    treasurers: (state) => state.treasurers,
    treasurer: (state) => state.treasurer,
    story: (state) => state.story,
    withdraw: (state) => state.withdraw,
    withdraws: (state) => state.withdraws,
    logout: (state) => {
        state.user = {};
        // state.isLoggedIn = false;
        state.token = "";
        localStorage.clear();
      },
};