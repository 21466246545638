<template>
    <div>
    <HomePageHeader></HomePageHeader>
    <div class="resources">
        <div class="resources-content" > 
            <img style="height: 100%;" src="@/assets/images/zigzag-texture.webp">
                <div class="banner-center">
                    <h4>HOME /RESOURCES</h4>
                    <h3>Resources</h3>
                </div>
            <img style="height: 100%;" src="@/assets/images/zigzag-texture.webp">
        </div>
        <div class="explanation-container">
            <h1>Everything You Need To Know About M-CHANGA Fundraising Features Explained:</h1>
            <div class="tabs">
                <button :class="{'active': activeTab === 'summary-features'}" @click="activeTab = 'summary-features'">Summary Features</button>
                <button :class="{'active': activeTab === 'verification-features'}" @click="activeTab = 'verification-features'">Verification Features</button>
                <button :class="{'active': activeTab === 'sharing'}" @click="activeTab = 'sharing'">Sharing and Referral</button>
            </div>
            <div v-if="activeTab === 'summary-features'">
                <div class="summary-container">
                    <div class="summary-numbers">1</div>
                    <div class="summary-title">
                        <span>Online Registration</span> 
                    </div>
                </div>
                <div class="summary-paragraph">
                    <p>Are you ready to enjoy simple, secure and convenient fundraising with M-CHANGA? Within minutes you will have a campaign ready to share to the world! Go to mchanga.africa/signup to register and activate your fundraiser.</p>
                </div>

                <div class="summary-container">
                    <div class="summary-numbers">2</div>
                    <div class="summary-title">
                        <span>Editing your fundraiser info</span> 
                    </div>
                </div>
                <div class="summary-paragraph">
                    <p>Fundraiser information can be edited at any time over the duration of your fundraiser. Do you want to edit your fundraiser name, target amount or expiry date? Sign in to your fundraiser and select <b>Fundraiser Details</b> on the menu.</p>
                </div>

                <div class="summary-container">
                    <div class="summary-numbers">3</div>
                    <div class="summary-title">
                        <span>Fundraiser statement</span> 
                    </div>
                </div>
                <div class="summary-paragraph">
                    <p>With M-Changa your fundraiser, donation, withdrawal and expense statements are available to you with a simple click! Sign in to your fundraiser and select <b>My Statement</b> on the menu</p>
                </div>

                <div class="summary-container">
                    <div class="summary-numbers">4</div>
                    <div class="summary-title">
                        <span>Fundraiser media</span> 
                    </div>
                </div>
                <div class="summary-paragraph">
                    <p>Add media to make your fundraiser message more powerful through video, images and a story! Sign in to your fundraiser and select <b>Images</b>, <b>Video</b> or <b>Story</b> on the menu.</p>
                </div>

                <div class="summary-container">
                    <div class="summary-numbers">5</div>
                    <div class="summary-title">
                        <span>Fundraiser patrons</span> 
                    </div>
                </div>
                <div class="summary-paragraph">
                    <p>A patron's role is to advocate for your fundraiser and ensure that it meets its target. A patrons name, personal message and photo (optional) can be added to your fundraiser page. Sign in to your fundraiser and select <b>Patron</b> on the menu.</p>
                </div>
            
            </div>
                <div v-if="activeTab === 'verification-features'">
                    <div class="verification-container">
                        <div class="verification-numbers">1</div>
                        <div class="verification-title">
                            <span>Supporting documents</span> 
                        </div>
                    </div>
                    <div class="verification-paragraph">
                        <p>M-Changa fundraisers require verification for due diligence. Are your fundraiser verification documents ready for upload? Sign in to your fundraiser and select <b>Documents</b> on the menu.</p>
                    </div>

                    <div class="verification-container">
                        <div class="verification-numbers">2</div>
                        <div class="verification-title">
                            <span>Treasurers</span> 
                        </div>
                    </div>
                    <div class="verification-paragraph">
                        <p>Adding treasurers to fundraisers increases accountability and transparency. You can add up to three treasurers to help co-manage your fundraiser. Sign in to your fundraiser and select <b>Treasurers</b> on the menu.</p>
                    </div>

                    <div class="verification-container">
                        <div class="verification-numbers">3</div>
                        <div class="verification-title">
                            <span>Withdrawal</span> 
                        </div>
                    </div>
                    <div class="verification-paragraph">
                        <p>You can make partial or full withdrawals of your funds as many times as you require over the course of your fundraiser. Sign in to your fundraiser and select <b>Withdraw</b> on the menu.</p>
                    </div>
                </div>
            <div v-if="activeTab === 'sharing'">
                <div class="sharing-container">
                    <div class="sharing-numbers">1</div>
                    <div class="sharing-title">
                        <span>Social Sharing</span>
                    </div>
                </div>
                <div class="sharing-paragraph">
                    <p>You can make partial or full withdrawals of your funds as many times as you require over the course of your fundraiser. Sign in to your fundraiser and select <b>Withdraw</b> on the menu.</p>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import HomePageHeader from './UI/HomePageHeader.vue';


export default {
    name: 'TheResources',
    components: {
        HomePageHeader
    },
    data() {
        return {
            activeTab: 'summary-features'
        };
    },
    methods: {
        getActiveTableSelector() {
        switch (this.activeTab) {
          case 'summary-features':
            return '.summary-container';
          case 'verification-features':
            return '.verification-features';
          case 'sharing':
            return '.sharing-referrals-features';
          default:
            return null;
          }
        },
    }
}
</script>

<style scoped>
.resources{
    font-family: museo_sans_rounded900;
}
.resources-content{
  /* background-color: #005803; */
  background-image: url(@/assets/images/top-banner-resources.jpg);
  width: 100%;
  height: 250px; 
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.banner-center {
    position: absolute;
    margin: 60px;
}
.banner-center h3 {
    font-family: museo_sans_rounded900;
    font-size: 55px;
    line-height: normal;
    color: #fcc82c;
    font-weight: 400;
}
.banner-center h4{
    font-family: Permanent Marker, cursive;
    font-size: 15px;  
    color: #c1f7d5;
}
.explanation-container{
    padding: 30px 100px;
}
.explanation-container h1{
    /* font-family: panton_blackregular; */
    font-size: 35px;
    text-transform: none;
    letter-spacing: normal;
    color: #475b06;
    line-height: normal;
}
.tabs {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.tabs button{
    line-height: 50px !important;
    font-family: museo_sans_rounded900;
    transition: all .2s;
    border: 1px solid #ccc;
    border-radius: 99px;
    width: 150px;
}
.tabs button.active {
    background: #fcc82c;
    color:  #475b06;
}
/* .span{

} */
.summary-container, .verification-container, .sharing-container {
    display: flex; 
    align-items:center; 
    padding: 20px 40px;
}
.summary-numbers, .verification-numbers, .sharing-numbers {
    width: 50px; 
    height: 50px; 
    border-radius: 9999px; 
    background-color: #005803; 
    display: flex; 
    align-items: center; 
    justify-content: center;
    color: #fff;
}
.summary-title, .verification-title, .sharing-title{
    margin-left: 30px;
    font-family: museo_sans_rounded900;
    font-size: 26px;
    color: #465a05;
}

.summary-paragraph, .verification-paragraph, .sharing-paragraph {
    display: flex; 
    padding: 0px 120px;
}
</style>