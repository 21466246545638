<template>
  <div>
    <MyFundraiserDetailsCard></MyFundraiserDetailsCard>
    <div class="fundraiser-update">
      <h2>Update Fundraiser Details</h2>
      <form @submit="updateFundraiser">
        <input v-model="title" type="text" placeholder="Fundraiser/Cause Name" required>
        <select v-model="selectedCategory" placeholder="Category" required>
          <option disabled value="">Type of Fundraiser</option>
          <option value="health">Health</option>
          <option value="education">Education</option>
          <option value="religious">Religious</option>
          <option value="wedding">Wedding</option>
          <option value="funeral">Funeral</option>
          <option value="civil society">Civil Society</option>
          <option value="business">Business</option>
          <option value="other">Other</option>
        </select>
        <div class="target-input">
          <button type="button" @click="decreaseTarget">-</button>
          <input v-model="targetAmount" type="number" placeholder="Target Amount" required>
          <button type="button" @click="increaseTarget">+</button>
        </div>
        <select v-model="selectedDuration" placeholder="Duration" required>
          <option disabled value="">Select Duration</option>
          <option value= 15>15 Days</option>
          <option value= 30>30 Days</option>
          <option value= 45>45 Days</option>
          <option value= 60>60 Days</option>
          <option value= 90>90 Days</option>
        </select> 
        <button type="submit">Update Details</button>
      </form>
    </div>
  </div>
  </template>
  
  <script>
  import { mapGetters, mapState } from 'vuex';
  import MyFundraiserDetailsCard from '../UI/MyFundraiserDetailsCard.vue';

  export default {
    name: 'UpdateFundraiser',
    components: {
      MyFundraiserDetailsCard
    },
    data() {
      return {
        title: '',
        selectedCategory: '',
        targetAmount: '',
        selectedDuration: '',
        errorMessage: '',
      };
    },
    computed: {
      ...mapState({
        singleFund: state => state.singleFund
      }),
      ...mapGetters(['singleFund']),
    },
    methods: {
       async updateFundraiser(event,user) {
        event.preventDefault();
        console.log("user", user)
        const updatedFundraiser = {
          title: this.title,
          selectedCategory: this.selectedCategory,
          targetAmount: this.targetAmount,
          selectedDuration: this.selectedDuration,
          userEmail: this.user.sub,
        };

        console.log('Creating with details:', updatedFundraiser);
        try {
         const response = await this.$store.dispatch('updateFundraiser', updatedFundraiser);
          if(response.data.success) {
            await this.$router.push('/dash');
          }
          else {
            this.errorMessage = response.data.message || 'Fundraiser updation failed. Please try again';
            console.error('Fundraiser updation failed:', response.data.message);
          }
          console.log('Fundraiser updated.');
          await this.$router.push('/dash');
        } catch (error) {
          this.errorMessage = 'Fundraiser updation failed. Please try again';
          console.error('Fundraiser updation failed:', error);
        }
      },
      decreaseTarget() {
        if (this.targetAmount > 0) {
          this.targetAmount -= 100;
        }
      },
      increaseTarget() {
        this.targetAmount += 100;
      }
    },
  }
  </script> 
  
  <style scoped>
  .fundraiser-update {
    flex-grow: 1;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  .fundraiser-update form {
    display: flex;
    flex-direction: column;
  }

.fundraiser-update input, .fundraiser-update select {
  padding: 8px; 
  margin-bottom: 12px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 20px;
  outline: none;
  transition: border-color 0.3s;
  text-align: center;
}
.target-input{
  display: flex;
  justify-content: center;
  align-items: center;
}
.fundraiser-update input:focus, .fundraiser-update select:focus {
  border-color: #007bff;
}

.fundraiser-update button {
  padding: 10px;
  font-size: 1em;
  color: #fff;
  margin: 8px;
  background-color: #f0a500;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.fundraiser-update button:hover {
  background-color: #cc8400;
}

.fundraiser-update-section {
  margin-top: 20px;
}

  </style>
  