<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style >
/* Global Styles */
body {
  font-family: museo_sans_rounded900;
  background-color: #f5f5f5;
  margin: 0;
  padding: 0;
  width: 100%;
  color: #333;
}

</style>

