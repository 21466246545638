<template>
    <div class="layout">
      <!-- Header Component -->
      <HeaderSideBar />
  
      <!-- Sidebar and content layout -->
      <div class="main">
        <SideBar2 />
  
        <!-- Main content area -->
        <div class="content">
          <router-view />
        </div>
      </div>
    </div>
  </template>
  
  <script>
import HeaderSideBar from './HeaderSideBar.vue';
import SideBar2 from './UsersView/SideBar2.vue';
  
  export default {
    components: {
      SideBar2,
      HeaderSideBar,
    },
  };
  </script>
  
  <style scoped>
  .layout {
    display: flex; 
    flex-direction: column; 
    height: 100vh; /* Full height to include header */
    overflow-y: scroll;
    cursor: pointer;
    
  }

  
  .main {
    display: flex;
    flex: 1;  
    overflow-y: scroll;
  }
  
  .SideBar2 {
    width: 250px;
    background-color: #f5f5f5;
    padding: 20px; 
  }
  
  .content {
    flex: 1;
    padding: 0px 20px;
    height: 100%;
    background-color: #f0f0f0;
    margin-top: 60px;
  }
  </style>
  