<template>
  <div v-if="processing">
    <p>Loading fundraiser details...</p>
  </div>
  <div v-else class="flex flex-shrink flex-wrap w-full gap-x-5">   
    <div  v-for="fund in fundraiserCard" :key="fund.fundraiserId" @click="goToSummary(fund)"> 
      <div class="fundraiser-card">
        <img src="@/assets/images/logo.png" alt="Smart People Africa">
        <div class="fundraiser-details">
          <h2>{{ fund.title }}</h2>
          <p>Raised: {{ fund.raisedAmount }}</p>
          <p>Target: {{ fund.targetAmount }}</p>
        </div>
      </div> 
   
    </div>
  </div>
</template>


  <script>
  import { mapGetters, mapState } from 'vuex';
 

  export default {
    name: 'FundraiserCard',
    computed: {
    ...mapState({
      fundraiserCard: (state) => state.fundraiserCard, 
    }),
    ...mapGetters(['fundraiserCard', 'processing', 'user']),
    },
    methods: {
     async goToSummary(fund) {  
      this.$store.commit('setSingleFund', fund) 
        this.$router.push('/summary')
      },
      
    },
    created() {
      this.$store.dispatch('fetchFundraisersByEmail');   
    },
  };
  </script>
  
  <style scoped>
  .fundraiser-card {
    border: 1px solid #ddd;
    justify-content: center;
    display: flex;
    height: 200px;
    margin-bottom: 10px;
    align-items: center;
    text-align: center;
    background-color: #ffffff;
    border-radius: 8px;
    width: 300px;
    flex-wrap: wrap;
    flex-direction: column;
    transition: transform 0.3s;
  }
  
  .fundraiser-card:hover {
    transform: translateY(-5px);
  }
  
  .fundraiser-logo {
    width: 100px;
    margin-bottom: 20px;
  }
  .fundraiser-details {
  display: flex;
  flex-direction: column;
  justify-content: center; 
  margin-top: 20px;
}

.fundraiser-details h2 {
  margin: 0;
  font-size: 1.5rem;
}

.fundraiser-details p {
  margin: 5px 0;
  font-size: 1rem;
}
  </style>
  