<template>
  <div class="card-container">
    <div class="card" v-for="(fundraiser, index) in fundraisers" :key="index">
      <!-- <img class="logo" src="path_to_your_logo" alt="Fundraiser Logo" /> -->
      <h3>{{ fundraiser.title }}</h3>
      <p>Raised: KSh {{ fundraiser.raisedAmount }}</p>
      <p>Donors: {{ fundraiser.donations.length }}</p>
      <button @click="viewDetails()">View Details</button>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    fundraiser() {
      return this.$store.state.fundraiser;
    },
  },
  methods: {
    viewDetails() {
      this.$router.push('/summary');
    },
  },
  mounted() {
    this.$store.dispatch('fetchFundraisers');
  },
};
</script>

<style scoped>
.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Space between cards */
  justify-content: flex-start; /* Align cards to the left */
}

.card {
  flex: 1 1 calc(33.333% - 40px); /* 3 cards per row with spacing */
  box-sizing: border-box;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  transition: box-shadow 0.3s ease;
  text-align: center; /* Center content */
}

.card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.logo {
  width: 50px; /* Adjust size as needed */
  height: auto;
  margin-bottom: 10px;
}

h3 {
  font-size: 1.2rem;
  margin: 10px 0;
}
</style>
