<template>
  <div class="flex w-full"> 
  <div class="fundraiser-details">
    <div class="header">
      <div class="fundraiser-info">
        <div>Fundraiser: {{ singleFund.title }}</div>
        <div>Account: {{ singleFund.fundraiserId }}</div>
        <div>Balance: KES {{ singleFund.balance }}</div>
        <div>Status: <span :class="statusClass">{{ singleFund.status }}</span>
        </div>
        </div>
      </div>
    </div>
  </div>

</template>


<script>
import { mapGetters, mapState } from 'vuex';
 
  export default {
    name: 'MyFundraiserDetailsCard',
    computed: {
      statusClass() {
        return this.singleFund.status === 'ACTIVE' ? 'active-status' : 'inactive-status';
      },
      ...mapState({
        fundraisers: state => state.fundraisers,
        singleFund: (state) => state.singleFund,
      }),
      ...mapGetters(['singleFund'])
    },
  };
</script>

<style scoped>
  .fundraiser-details {
    padding: 20px;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 1px solid #ccc;
  }
  
  .fundraiser-info {
    display: flex;
    flex-direction: column;
  }
  
  .active-status {
    color: green;
  }

  .inactive-status {
    color: red;
  }
  
  .link {
    margin-top: 10px;
  }
</style>