<template>
  <div>
    <MyfundraiserDetailsCard></MyfundraiserDetailsCard>
    <div class="summary-page" style="display: flex; flex-direction: column;">
      <h2>Summary</h2>
      <div class="alert alert-danger">
        Unsurveyed: Kindly call a customer care agent on +254705484192 or request a callback by emailing your phone number to support@mchanga.africa
      </div>
      <!-- <div class="progress-bar">
        <div class="progress" :style="{ width: progressPercentage + '%' }"></div>
      </div> -->
      <!-- <p v-if="progressPercentage < 100">
      Your fundraiser raiser setup is at {{ progressPercentage }}%. It seems there is more you can do to make your fundraiser raiser a success. Click the button below to see what you can do:
    </p>
    <p v-else>
      Your fundraiser raiser is fully set up.
    </p> -->
    <!-- <button v-if="progressPercentage < 100" class="btn btn-warning" @click="showTasks">More Tasks</button> -->
    <!-- <div v-if="tasksVisible && progressPercentage < 100" class="tasks">
        <router-link v-if="!fundraiser.documents" to="/documents" class="task-link">Add documents to verify the fundraiser raiser</router-link>
        <router-link v-if="fundraiser.treasurers.length < 3" to="/treasurers" class="task-link">Add three treasurers</router-link>
        <router-link v-if="!fundraiser.shared" to="/share" class="task-link">Share with users</router-link>
        <router-link v-if="!fundraiser.media" to="/images" class="task-link">Add an image, video, or post</router-link>
    </div> -->
      <div style="display: flex; gap: 10px;">
        <div class="view-button">
          <button @click="goToFundStatement()">View Statement</button>
        </div>
        <div class="view-button">
          <button @click="goToFundWithdrawal()">Withdraw</button>
        </div>
      </div>
      <div class="summary-stats">
        <div class="stat">
          <div class="stat-value">{{ singleFund.title }}</div>
          <div class="stat-label">TITLE</div>
        </div>
        <div class="stat">
          <div class="stat-value">KES{{ singleFund.raisedAmount }}</div>
          <div class="stat-label">AMOUNT RAISED</div>
        </div>
        <div class="stat">
          <div class="stat-value">{{ singleFund.donations }}</div>
          <div class="stat-label">DONORS</div>
        </div>
        <div class="stat">
          <div class="stat-value">KES {{ singleFund.targetAmount }}</div>
          <div class="stat-label">TARGET</div>
        </div>
        <!-- <div class="stat">
          <div class="stat-value">{{ singleFund.daysLeft }}</div>
          <div class="stat-label">DAYS LEFT</div>
        </div> -->
      </div>
      <div class="latest-donations">
        <h3>Latest Donations</h3>
        <p class="note">NB: In compliance with the Kenyan data protection laws, personal information on your statements will be truncated.</p>
        <div class="download-buttons">
          <button class="btn btn-pdf" @click="downloadPDF">PDF</button>
          <button class="btn btn-excel" @click="downloadExcel">Excel</button>
          <button class="btn btn-whatsapp" @click="shareOnWhatsApp">WhatsApp</button>
        </div>
        <table class="donations-table">
          <thead>
            <tr>
              <th>Donor</th>
              <th>Contact</th>
              <th>Amount (KES)</th>
              <th>Trans Ref</th>
              <th>Donation Channel</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="donation in donations" :key="donation.donationId">
              <td>{{ donation.donorName }}</td>
              <td>{{ donation.donorContact }}</td>
              <td>{{ donation.amountDonated }}</td>
              <td>{{ donation.donorTransactionReference }}</td>
              <td>{{ donation.paymentMethod }}</td>
              <td>{{ donation.date }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  </template>
  
  <script>
  import { mapState, mapGetters } from 'vuex';
  import MyfundraiserDetailsCard from '../UI/MyFundraiserDetailsCard.vue';
  import jsPDF from 'jspdf';
  import autoTable from 'jspdf-autotable';
  import XLSX from 'xlsx';

  export default {
    name: 'UserSummary',
    components: {
      MyfundraiserDetailsCard
    },
    data() {
      return {
        tasksVisible: false
      };
    },
    computed: {
      ...mapState({
        singleFund: (state) => state.singleFund,
        donations: (state) => state.donations
      }),
      ...mapGetters(['singleFund']),
      // progressPercentage() { 
      //   let progress = 0;
      //     if (this.fundraiser.documents) progress += 25;
      //     if (this.fundraiser.treasurers && this.fundraiser .treasurers.length >= 3) progress += 25;
      //     if (this.fundraiser.shared) progress += 25;
      //     if (this.fundraiser.media) progress += 25;
      //   return progress;
      // },  
    },
    methods: {
      goToFundStatement() {
        this.$router.push('/statement');
      },
      goToFundWithdrawal() {
        this.$router.push('/withdraw');
      },
      showTasks() {
        this.tasksVisible = !this.tasksVisible;
      },
      // truncate(text) {
      //   if (!text) return '';
      //   return text.length > 6 ? text.substring(0, 6) + '...' : text;
      // },
      downloadPDF() {
        const doc = new jsPDF();
        autoTable(doc, { html: '.donations-table' });
        doc.save('donations.pdf');
      },
      downloadExcel() {
        const table = document.querySelector('.donations-table');
        const workbook = XLSX.utils.table_to_book(table);
        XLSX.writeFile(workbook, 'donations.xlsx');
      },
      shareOnWhatsApp() {
        const url = 'https://api.whatsapp.com/send?text=' + encodeURIComponent(window.location.href);
        window.open(url, '_blank');
      },
    },
  };
  </script>
  
  <style scoped>
  .summary-page {
    padding: 20px;
  }
  
  .alert {
    background-color: #f8d7da;
    color: #721c24;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 20px;
  }
  
  .progress-bar {
    background-color: #e9ecef;
    border-radius: 4px;
    overflow: hidden;
    margin: 20px 0;
  }
  
  .progress {
    background-color: #fcc82c;
    height: 10px;
  }
  
  .btn-warning {
    background-color: #fcc82c;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
    color: #fff;
  }
  
  .tasks {
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    margin-top: 20px;
  }
  .task-link {
    display: block;
    color: #070606;
    text-decoration: none;
    margin-bottom: 10px;
    width: 300px;
    padding: 10px;
    border-radius: 4px;
    background-color: #e9ecef;
    transition: background-color 0.3s ease;
  }

  .task-link:hover {
    background-color: #fcc82c;
    color: #fff;
  }
  .view-button{
    /* border: 1px solid black; */

    border: 1px solid #fcc82c;
    color: black;
    font-weight: 100;
    background-color: #fcc82c;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 120px;
    padding: 5px;
  }
  .view-button:hover{
    color: black;
    background-color: #cc8400;
    border: 1px solid #cc8400;
  }
  
  .summary-stats {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .stat {
    text-align: center;
    background-color: #eeeeee;
    padding: 20px;
    border-radius: 4px;
    width: 150px;
  }
  
  .stat-value {
    font-size: 24px;
    font-weight: bold;
  }
  
  .stat-label {
    margin-top: 10px;
    font-size: 14px;
    color: #6c757d;
  }
  
  .latest-donations {
    margin-top: 20px;
  }
  
  .latest-donations h3 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .note {
    font-size: 12px;
    color: #6c757d;
    margin-bottom: 10px;
  }
  
  .download-buttons {
    margin-bottom: 10px;
    border-radius: 20px;
  }
  
  .download-buttons .btn {
    margin-right: 10px;
    border-radius: 10px;
    width: 70px;
  }
  
  .btn-pdf {
    background-color: #d9534f;
    color: #fff;
  }
  
  .btn-excel {
    background-color: #5cb85c;
    color: #fff;
  }
  
  .btn-whatsapp {
    background-color: #25d366;
    color: #fff;
  }
  
  .donations-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .donations-table th,
  .donations-table td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  .donations-table th {
    background-color: #f8f9fa;
    font-weight: bold;
  }

  </style>
  