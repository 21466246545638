<template>
    <div class="fundraiser-detail">
      <h1>{{ fundraiser.title }}</h1>
      <p>{{ fundraiser.description }}</p>
      <p>Target Amount: {{ fundraiser.targetAmount }}</p>
      <p>Current Amount: {{ fundraiser.raisedAmount }}</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'FundraiserDetail',
    props: {
      id: {
        // type: String,
        required: true
      }
    },
    data() {
      return {
        fundraiser: {}
      };
    },
    created() {
      this.fetchFundraiser();
    },
    methods: {
      async fetchFundraiser() {
        try {
          const response = await this.$store.dispatch('fetchFundraiser', this.id);
          this.fundraiser = response.data;
        } catch (error) {
          console.error('Failed to fetch fundraiser:', error);
        }
      }
    }
  };
  </script>
  
  <style scoped>
.fundraiser-detail {
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  font-family: Arial, sans-serif;
}

h1 {
  font-size: 2.5em;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.description {
  font-size: 1.2em;
  color: #666;
  margin-bottom: 20px;
  text-align: justify;
}

.amount {
  font-size: 1.1em;
  color: #007bff;
  margin-bottom: 10px;
}

.amount:last-of-type {
  color: #28a745;
}

@media (max-width: 600px) {
  .fundraiser-detail {
    padding: 10px;
  }

  h1 {
    font-size: 2em;
  }

  .description {
    font-size: 1em;
  }

  .amount {
    font-size: 1em;
  }
}
</style>
  