<template>
    <header class="header-class">
      <div class="logo">
        <button @click="goToHomePage">
          <img src="@/assets/images/logo.png" alt="Smart People Africa">
        </button>
      </div>
      <div style="color: grey; position: relative; display: inline-block;">
      <button @click="toggleMenu" :aria-expanded="isMenuOpen" style="cursor: pointer;">
        <font-awesome-icon class="profile" :icon="['fas', 'user']" />
      </button>
      <div v-if="isMenuOpen" style="position: absolute; top: 100%; right: 0; background: white; border: 1px solid #ccc; border-radius: 5px; width: 200px; ">
        <div>
          <h2><font-awesome-icon class="icon" :icon="['fas', 'user']" />{{ user.sub }}</h2>
        </div>
        <ul role="menu" style="list-style: none; margin: 0; padding: 10px;">
          <button style="cursor: pointer;">
             View Profile
          </button>
          <button style="cursor: pointer;">
            <font-awesome-icon class="icon" :icon="['fas', 'gear']" /> Account Settings
          </button>
          <button @click="handleLogout" style="cursor: pointer;">
            <font-awesome-icon class="icon" :icon="['fas', 'arrow-right-from-bracket']" /> Log Out
          </button>
        </ul>
      </div>
    </div>
    </header>
  </template>
  
  <script>
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { library } from '@fortawesome/fontawesome-svg-core';
    import { faUser, faGear, faArrowRightFromBracket,faHouse } from '@fortawesome/free-solid-svg-icons';
    import { mapGetters, mapState } from 'vuex';
    library.add(faUser, faGear, faArrowRightFromBracket,faHouse);

  export default {    
    name: "HeaderSideBar",
    data() {
    return {
        isMenuOpen: false,
        FontAwesomeIcon
      }
    },
    methods: {
      toggleMenu() {
        this.isMenuOpen = !this.isMenuOpen;
      },
      handleLogout() {
      this.$store.dispatch('logout');
      this.$router.push('/');
    },
    },
    computed:{
      ...mapGetters(['user']),
      ...mapState({
        user: (state)=> state.user,
      })
    }

  };
  </script>
  
  <style scoped>
  .header-class {
    background-color: #fff;
    color: white;
    height: 60px; 
    padding: 10px;
    justify-content: space-between;
    display: flex;
    z-index: 1;
    position: fixed;
    width: 100%;
  }
  .icon {
    font-size: 1.2em; /* Adjust icon size if needed */
    transition: margin-right 0.3s ease;
    padding-top: 10px;
    padding-right: 10px;
    padding-left: 5px;
    color: black;
}
.profile{
  height: 30px;
  width: 30px;
  padding-right: 20px;
}
  </style>
  