<template>
  <div>
    <MyFundraiserDetailsCard></MyFundraiserDetailsCard>
    <div style="display: flex;justify-content: start;align-items: center; font-size: 30px;font-weight: 500;">
      <h2>Fundraiser Video </h2>
    </div>
    <div class="video-upload">
      <form @submit.prevent="saveVideo">
        <div style="padding: 20px;">
          <label for="videoUrl">Paste Video URL</label>
          <textarea id="videoUrl" v-model="urlCode" required></textarea>
        </div>
        <div style="display: flex; gap: 10px;">
          <div class="video-btn">
            <button @click="saveVideo" type="submit">Save Video</button>
          </div>
            <div>
              <button class="video-delete-btn" v-if="urlCode" @click="deleteVideo">Delete Video</button>
            </div>
        </div>
      </form>
    </div>
  </div>
  </template>
  
  <script>
  import { mapGetters, mapState } from 'vuex';
  import MyFundraiserDetailsCard from '../UI/MyFundraiserDetailsCard.vue';
  
  export default {
    name: 'VideoUpload',
    components: {
      MyFundraiserDetailsCard
    },
    data() {
      return {
        urlCode: '',
      };
    },
    computed: {
      ...mapState({
        singleFund: state => state.singleFund,
        video: state => state.video,
      }),
      ...mapGetters(['singleFund', 'video', 'videos'])
    },
    methods:{
      deleteVideo() {
          this.urlCode = '';
      },
      async saveVideo(){
        this.$store.commit('setProcessing', true)
        const videoData = {
          fundraiserId: this.singleFund.fundraiserId,
          videoUrl: this.urlCode
        }
        this.$store.dispatch('uploadVideo', videoData)
      }
    }
  };
  </script>
  
  <style scoped>
  .video-upload {
    flex-grow: 1;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    display: flex;
  }
  
  
  .video-upload textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  .text-area{
    width: 400px;
  }
  .video-btn{
    border: 1px solid #FCC82C;
    background-color: #FCC82C;
    border-radius: 20px; 
    text-align: center;
    margin-left: 20px;
    margin-bottom: 10px;
    width: 100px; 
    padding: 5px;
  }
  .video-delete-btn{
    border: 1px solid red;
    background-color: red;
    color: #fff;
    border-radius: 20px; 
    text-align: center;
    width: 100px; 
    padding: 5px;
  }
  </style>
  