<template>
    <div class="add-fundraiser-wrapper">
      <div class="create">
      <h1>Add New Fundraiser</h1>
      <h2>Enter Fundraiser details below:</h2>
      <form @submit="register">
        <input v-model="title" type="text" placeholder="Fundraiser/Cause Name" required>
        <select v-model="selectedCategory" placeholder="Category" required>
          <option disabled value="">Type of Fundraiser</option>
          <option value="health">Health</option>
          <option value="education">Education</option>
          <option value="religious">Religious</option>
          <option value="wedding">Wedding</option>
          <option value="funeral">Funeral</option>
          <option value="civil society">Civil Society</option>
          <option value="business">Business</option>
          <option value="other">Other</option>
        </select>
        <div class="target-input">
          <button type="button" @click="decreaseTarget">-</button>
          <input v-model="targetAmount" type="number" placeholder="Target Amount" required>
          <button type="button" @click="increaseTarget">+</button>
        </div>
        <select v-model="selectedDuration" placeholder="Duration" required>
          <option disabled value="">Select Duration</option>
          <option value= 15>15 Days</option>
          <option value= 30>30 Days</option>
          <option value= 45>45 Days</option>
          <option value= 60>60 Days</option>
          <option value= 90>90 Days</option>
        </select> 
        <button type="submit">Create Fundraiser</button>
      </form>
      <div class="home-section">
        <p>Click here to go to <router-link to="/dash">Dashboard</router-link></p>
      </div>
    </div>
    </div>
  </template>
  
  <script>
  import { mapActions, mapGetters } from 'vuex';
  
  export default {
    data() {
      return {
        title: '',
        selectedCategory: '',
        targetAmount: 0,
        selectedDuration: '',
        errorMessage: '',
      };
    },
    computed: {
      ...mapActions(['addFundraiser']),
      ...mapGetters(['user']),
    },
    methods: {
      async register(event,user) {
        event.preventDefault();
        console.log("user",user)

        const newFundraiser = {
          title: this.title,
          category: this.selectedCategory,
          targetAmount: this.targetAmount,
          selectedDuration: this.selectedDuration,
          userEmail:this.user.sub,
        };
        console.log('Creating with details:', newFundraiser);
        try {
         const response = await this.$store.dispatch('createFundraiser', newFundraiser);
          if(response.data.success) {
            await this.$router.push('/dashboard');
          }
          else {
            this.errorMessage = response.data.message || 'Fundraiser creation failed. Please try again';
            console.error('Fundraiser creation failed:', response.data.message);
          }
          console.log('Fundraiser registered with fundraiser. Proceed with activations.');
          await this.$router.push('/dash');
        } catch (error) {
          this.errorMessage = 'Fundraiser creation failed. Please try again';
          console.error('Fundraiser creation failed:', error);
        }
      },
      decreaseTarget() {
        if (this.targetAmount > 0) {
          this.targetAmount -= 100;
        }
      },
      increaseTarget() {
        this.targetAmount += 100;
      }
    },
  };
  </script>
  
  <style scoped>
  .add-fundraiser-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70%;
  }

.create {
  width: 400px;
  padding: 10px 20px; 
  text-align: center;
}

.create h1 {
  font-size: 1.8em; 
  margin-bottom: 15px; 
  color: #333;
}

.create h2 {
  font-size: 0.9em;
  margin-bottom: 10px;
  color: #333;
}

.create form {
  display: flex;
  flex-direction: column;
}

.create input, .create select {
  padding: 8px; 
  margin-bottom: 12px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 20px;
  outline: none;
  transition: border-color 0.3s;
}

.create input:focus, .create select:focus {
  border-color: #007bff;
}

.create button {
  padding: 10px;
  font-size: 1em;
  color: #fff;
  margin: 8px;
  background-color: #f0a500;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.create button:hover {
  background-color: #cc8400;
}

.create-section {
  margin-top: 20px;
}

.home-section p {
  margin: 0;
  font-size: 1em;
}

.home-section a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.home-section a:hover {
  text-decoration: underline;
}
  </style>
  