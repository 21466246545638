<template>
  <header class="header"> 
    <div class="container_header">
      <div class="header-right">
        <div class="social-icons">
          <a href="https://www.facebook.com" target="_blank">
          <img src="@/assets/images/facebook.webp" alt="Facebook" class="social-icon" />
        </a>
        <a href="https://www.twitter.com" target="_blank">
          <img src="@/assets/images/twitter.webp" alt="Twitter" class="social-icon" />
        </a>
        <a href="https://www.linkedin.com" target="_blank">
          <img src="@/assets/images/linkedin.webp" alt="LinkedIn" class="social-icon" />
        </a>
        <a href="https://www.youtube.com" target="_blank">
          <img src="@/assets/images/youtube.webp" alt="YouTube" class="social-icon" />
        </a>
        <a href="https://www.instagram.com" target="_blank">
          <img src="@/assets/images/instagram.webp" alt="Instagram" class="social-icon" />
        </a>
      </div>
      <a href="tel:+254705484192" class="contact">
        <img src="@/assets/images/ic-phone.webp" alt="Phone" class="contact-icon" /> +254 (7) 05 484 192
      </a>
      <a href="mailto:support@mchanga.africa" class="contact">
        <img src="@/assets/images/ic-mail.webp" alt="Email" class="contact-icon" /> support@mchanga.africa
      </a>
      </div>
    </div>
    <div class="spa-container">
      <div class="logo">
        <button @click="goToHomePage">
          <img src="@/assets/images/logo.png" alt="Smart People Africa">
        </button>
      </div>
      <div class="header-content"> 
        <nav class="nav-content">
          <router-link to="/resources">Resources</router-link>
          <router-link to="/register">Register</router-link>
        </nav>
        <button class="login-btn" @click="goToLogin">Login</button>
      </div>
    </div>
  </header>
</template>

<script>

import { mapState } from 'vuex';


export default {
name: 'HomePageHeader',
computed: {
  ...mapState(['fundraiser']),
},
methods: {
  goToHomePage() {
      this.$router.push("/");
    },
    goToLogin() {
      this.$router.push("/login");
    },
}
};
</script>

<style scoped>
.header {
background-color: #fff;
width: 100%; 
position: fixed;
z-index: 100;
height: 100px; 
padding: 0px 10px;
}

.container_header {
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
height: auto;
}

.header-content {
display: flex;
align-items: center;
margin-left: auto;
}
.nav-content{
display: flex;
gap: 10px;
}

nav a {
text-decoration: none;
color: #333;
font-weight: bold;

}

.login-btn, .user-info button{
background-color: #f0a500;
color: #fff;
border: none;
padding: 10px 15px;
border-radius: 30px;
cursor: pointer;
margin-left: 20px;
transition: background-color 0.3s;

}

.login-btn:hover {
background-color: #cc8400;
}
.logo img{
font-size: 24px;
font-weight: bold;
color: #333;
width: 150px;
height: auto;
margin: 10px;
align-items: center;
justify-content: center;
display: flex;
}
.spa-container {
display: flex;
justify-content: space-between;
align-items: center;
flex-wrap: wrap;
}

.header-right {
display: flex;
align-items: center;
justify-content: flex-end; /* Aligns items to the right */
gap: 10px; 
flex-grow: 1; /* Allows the header-right to take up available space */
flex-wrap: wrap;
align-self: flex-end;
}

.social-icons {
display: flex;
gap: 5px; /* Space between social icons */
align-self: flex-end;
}

.social-icon {
width: 24px;
height: 24px;
transition: transform 0.3s;
}

.social-icon:hover {
transform: scale(1.1);
}

.contact {
display: flex;
align-items: start;
text-decoration: none;
color: #000;
font-size: 14px;
align-self: flex-end;

}

.contact-icon {
/* align-items: center; */
width: 20px;
height: 20px;
margin-right: 8px;
}

/* Media Queries for Responsiveness */
@media screen and (max-width: 768px) {
.container {
  flex-direction: row; 
  align-items: flex-end; 
}

.header-right {
  justify-content: flex-end; 
  flex-direction: row; 
  flex-wrap: wrap; 
  align-items: flex-end; 
  gap: 10px; 
}

.social-icons {
  justify-content: flex-end; /* Center social icons */
  gap: 3px; /* Reduce space between icons */
  align-self: flex-end;
}

.contact {
  align-items: start;
  justify-content: flex-end; 
  align-self: flex-end;
  font-size: 12px; /* Adjust font size for smaller screens */
}
}

</style>