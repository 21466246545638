<template>
   <!-- <div class="bg-red-500 h-full w-[300px] mt-[50px] "> sidebar</div> -->
   <div class="sidebar-wrapper">
        <div class="w-[300px]">
         <aside  @class="{ SideBar2}">
            <button class="toggle-button" @click="toggleSideBar">
                <font-awesome-icon class="icon" :icon="['fas', 'house']" />Home</button>
          <div>
            <nav>
              <ul>
                <li style="font-weight: 800;" class="side-bar-list" @click="navigateTo('DashBoard')"><font-awesome-icon class="icon" :icon="['fas', 'list-check']" />YOUR FUNDRAISERS</li>
                <li class="side-bar-list" @click="navigateTo('AllUserStatements')"><font-awesome-icon class="icon" :icon="['fas', 'wallet']" />My Statement</li>
                <li class="side-bar-list" @click="navigateTo('WithdrawMoney')"><font-awesome-icon class="icon" :icon="['fas', 'money-bill-transfer']" />Withdraw</li>
              </ul>
            </nav>
          </div>
          <h3 class="side-bar-headings" v-if="!isCollapsed">VERIFICATIONS</h3>
          <div>
            <nav>
              <ul>
                <li class="side-bar-list" @click="navigateTo('DocumentUpload')"><font-awesome-icon class="icon" :icon="['fas', 'paperclip']" />Documents</li>
                <li class="side-bar-list" @click="navigateTo('FundraiserCustodians')"><font-awesome-icon class="icon" :icon="['fas', 'gem']" />Treasurers</li>
              </ul>
            </nav>
          </div>
          <h3 class="side-bar-headings" v-if="!isCollapsed">PERSONALIZATION</h3>
          <div>
            <nav>
              <ul>
                <li class="side-bar-list" @click="navigateTo('UpdateFundraiser')"><font-awesome-icon class="icon" :icon="['fas', 'pen']" />Fundraiser Details</li>
                <li class="side-bar-list" @click="navigateTo('FundraiserStory')"><font-awesome-icon class="icon" :icon="['fas', 'paragraph']" />Story</li>
                <li class="side-bar-list" @click="navigateTo('ImageUpload')"><font-awesome-icon class="icon" :icon="['fas', 'image']" />Images</li>
                <li class="side-bar-list" @click="navigateTo('VideoUpload')"><font-awesome-icon class="icon" :icon="['fas', 'video']" />Video</li>
                <li class="side-bar-list" @click="navigateTo('FundraiserPatron')"><font-awesome-icon class="icon" :icon="['fass', 'image-portrait']" />Patrons</li>
              </ul>
            </nav>
          </div>
          <h3 class="side-bar-headings" v-if="!isCollapsed">UTILITIES</h3>
          <div>
            <nav>
              <ul>
                <li class="side-bar-list" @click="navigateTo('FeedbackForm')"><font-awesome-icon class=icon :icon="['fas', 'message']" />Feedback</li>
                <li class="side-bar-list" @click="navigateTo('SharedFundraiser')"><font-awesome-icon class="icon" :icon="['fass', 'share-nodes']" />Share</li>
                <!-- <li @click="navigateTo('AffiliateForm')">M-Changa Affiliates</li> -->
              </ul>
            </nav>
          </div>
        </aside>   
        </div> 
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { library } from '@fortawesome/fontawesome-svg-core';
    import { faHouse } from '@fortawesome/free-solid-svg-icons';
    library.add(faHouse);

    export default {
    name: 'SideBar',
    components:{
        FontAwesomeIcon
    },
    props: ['collapsed'],
    data() {
      return {
        isCollapsed: false
      };
    },
   
    methods: {
    //   toggleSideBar() {
    //     this.isCollapsed = !this.isCollapsed;
    //     this.$emit('toggleSideBar');
    //   },
      navigateTo(routeName) {
        this.$router.push({ name: routeName });      }
    },
    computed: {
      ...mapGetters(['user'])
    }
  };
</script>

<style scoped>
.sidebar-wrapper{
    display: flex;
    overflow-y: scroll;
    margin-top: 50px;
    height: 98vh;
}
.toggle-button {
    position: relative;
    /* background-color: #fff; */
    padding: 10px;
    width: 100%;
    display: flex;
    justify-content: flex-start; /* Align items to the left */
    align-items: center; /* Center vertically */
    text-align: left;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
}
.icon {
    font-size: 1.2em; /* Adjust icon size if needed */
    transition: margin-right 0.3s ease;
    padding: 15px;
    color: #FCC82C;
}
.side-bar-list{
   display: flex;      
   justify-content: flex-start;
   align-items: center;text-align: left;
}
.side-bar-headings{
    display: flex; 
    font-weight: 800;
    justify-content: left; 
    align-items:center; margin:10px
}
</style>