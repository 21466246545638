
<template>
  <div class="registration-wrapper">
    <div class="register">
      <h1>Register</h1>
      <h2>Enter your details below to proceed:</h2>
      <form @submit="register">
        <input v-model="userEmail" type="userEmail" placeholder="Email" required>
        <input v-model="mobileNumber" type="text" placeholder="Mobile Number" required>
        <input v-model="password" type="password" placeholder="Password" required>
        <input v-model="userConfirmationPassword" type="password" placeholder="Confirm Password" required>
        <!-- <input v-model="title" type="text" placeholder="Fundraiser/Cause Name" required>
        <select v-model="selectedCategory" placeholder="Category" required>
          <option disabled value="">Type of Fundraiser</option>
          <option value="health">Health</option>
          <option value="education">Education</option>
          <option value="religious">Religious</option>
          <option value="wedding">Wedding</option>
          <option value="funeral">Funeral</option>
          <option value="civil society">Civil Society</option>
          <option value="business">Business</option>
          <option value="other">Other</option>
        </select>
        <div class="target-input">
          <button type="button" @click="decreaseTarget">-</button>
          <input v-model="targetAmount" type="number" placeholder="Target Amount" required>
          <button type="button" @click="increaseTarget">+</button>
        </div>
        <select v-model="selectedDuration" placeholder="Duration" required>
          <option disabled value="">Select Duration</option>
          <option value= 15>15 Days</option>
          <option value= 30>30 Days</option>
          <option value= 45>45 Days</option>
          <option value= 60>60 Days</option>
          <option value= 90>90 Days</option>
        </select>  -->
        <button type="submit">Next</button>
      </form>
      <div class="home-section">
        <p>Click here to go to <router-link to="/">Home Page</router-link></p>
      </div>
    </div>
  </div>
</template>

<script>
import BASE_URL from '@/config';
import axios from 'axios';


export default {
  name: 'RegisterView',
  data() {
    return {
      userEmail: '',
      mobileNumber: '',
      password:'',
      userConfirmationPassword: '',
      // title: '',
      // selectedCategory: '',
      // targetAmount: 0,
      // selectedDuration: '',
      errorMessage: ''
    };
  },
  methods: {
    async register(event) {
      event.preventDefault();
      this.loading = true;
        if (this.password !== this.userConfirmationPassword) {
        this.errorMessage = "Passwords do not match";
        return;
      }

      const newUserDetails = {
        userEmail: this.userEmail,
        password: this.password,
        mobileNumber: this.mobileNumber,
         // title: this.title,
        // category: this.selectedCategory,
        // targetAmount: this.targetAmount,
        // selectedDuration: this.selectedDuration,
      };
     await axios.post(BASE_URL+'/api/create-user', newUserDetails, 
              {
              headers: {
                'Content-Type': 'application/json'
                },
              })
              .then(res =>{
                if(res.data.success){
                  window.location.href =`/login`;      
                }else{
                  console.log("errrr")
                  this.errorMessage = "Bad Credentials";
                }
                this.loading = false;
              })
    },
    // decreaseTarget() {
    //   if (this.targetAmount > 0) {
    //     this.targetAmount -= 100;
    //   }
    // },
    // increaseTarget() {
    //   this.targetAmount += 100;
    // }
  }
};
</script>
  
  <style scoped>
  .registration-wrapper {
    background-image: url(@/assets/images/register-image.png);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 600px;
}

.register {
  width: 400px;
  padding: 10px 20px; 
  background-color: #fff;
  border-radius: 20px;
  text-align: center;
  opacity: 0.95;
}

.register h1 {
  font-size: 1.8em; 
  /* margin-bottom: 5px;  */
  color: #333;
}

.register h2 {
  font-size: 0.9em;
  /* margin-bottom: 10px; */
  color: #333;
}

.register form {
  display: flex;
  flex-direction: column;
}

.register input, .register select {
  padding: 8px; 
  margin-bottom: 12px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 20px;
  outline: none;
  transition: border-color 0.3s;
}

.register input:focus, .register select:focus {
  border-color: #007bff;
}

.register button {
  padding: 10px;
  font-size: 1em;
  color: #fff;
  margin: 8px;
  background-color: #f0a500;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.register button:hover {
  background-color: #cc8400;
}

.register-section {
  margin-top: 20px;
}

.home-section p {
  margin: 0;
  font-size: 1em;
}

.home-section a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.home-section a:hover {
  text-decoration: underline;
}
  </style>
  